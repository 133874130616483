import { Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUser,
  getUserRoles,
  getUsers,
  saveUser,
  updateUser,
} from "../../apis/user";
import { Role } from "../../interfaces/User";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
import { AxiosError } from "axios";
import { clinicVisitType } from "../../interfaces/Visit";
import { getClinicVisitTypes } from "../../apis/visit";

const AddUser: React.FC<{}> = () => {
  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    degree: "",
    registrationNumber: "",
    address: "",
    password: "",
    preferredLanguage: "en",
    roles: [],
    defaultClinicVisitTypeId:""
  });
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [clinicVisitTypes, setClinicVisitTypes] = useState<Array<clinicVisitType>>([]);
  const Option = Select;
  const [errors, setErrors] = useState<any>();
  const [loggedInUser, setLoggedInUser] = useState<any>();
  const { showNotification } = useNotification();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    const userObj = window.localStorage.getItem("user");
    if (userObj) {
      const user = JSON.parse(userObj);
      setLoggedInUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
    fetchRoles();
    fetchClinicVisitTypes();
  }, [userId]);

  const fetchRoles = () => {
    getUserRoles().then((res: any) => {
      setRoles(res.data);
    });
  };

  const fetchClinicVisitTypes = () => {
    getClinicVisitTypes().then((res: any) => {
      setClinicVisitTypes(res.data);
    });
  };

  const fetchUser = (id: any) => {
    getUser(id).then((res: any) => {
      setUser(res.data);
    });
  };

  const onSave = () => {
    if (userId) {
      updateUserData(userId);
    } else {
      saveUserData();
    }
  };

  const saveUserData = async () => {
    const { hasErrors, validationErrors } = await validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveUser({ ...user })
      .then(() => {
        showNotification("success", "User added successfully", "", "topRight");
        navigate("/users");
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          setErrors(err.response.data.errors);
        }
        showNotification("error", err.response.data.message, "", "topRight");
      });
  };

  const updateUserData = async (id: any) => {
    const { hasErrors, validationErrors } = await validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);

    updateUser({ ...user, id })
      .then(() => {
        if (parseInt(id) === loggedInUser.id) {
          window.localStorage.setItem("user", JSON.stringify(user));
        }
        showNotification(
          "success",
          "User updated successfully",
          "",
          "topRight"
        );
        navigate("/users");
      })
      .catch((err) => {
        if (err.response?.data?.errors) {
          setErrors(err.response.data.errors);
        }
        showNotification("error", err.response.data.message, "", "topRight");
      });
  };

  const handleRoleChange = (roles: any) => {
    setUser({
      ...user,
      roles,
    });
  };

  const handleClinicVisitTypeChange = (defaultClinicVisitTypeId: any) => {
    setUser({
      ...user,
      defaultClinicVisitTypeId,
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};

    if (user?.firstName === "") {
      validationErrors.firstName = "* First name is required";
    }

    if (user?.phone === "") {
      validationErrors.phone = "* Phone is required";
    } else if (user.phone && user.phone.length !== 10) {
      validationErrors.phone = "Phone number should be 10 digits";
    }

    if (user?.lastName === "") {
      validationErrors.lastName = "* Last name is required";
    }

    if (user?.email === "") {
      validationErrors.email = "* Email is required";
    }

    if (user.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
      validationErrors.email = "Invalid email format";
    }

    if (user?.roles.length === 0) {
      validationErrors.roles = "Select atleast one role";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <div className="flex flex-col flex-grow p-4 ml-2 bg-white rounded-lg">
      <h3>
        <b>
          {userId ? t("update") : t("add")} {t("user")}
        </b>
      </h3>
      <div className="grid w-full grid-cols-1 gap-4 p-4">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("first_name")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              value={user?.firstName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z]*$/.test(value)) {
                  setUser({ ...user, firstName: e.target.value });
                }
              }}
              name="firstName"
            />
            {errors && errors.firstName && (
              <span className="text-sm text-red-500 leading-0">
                {errors.firstName}
              </span>
            )}
          </div>
          <div className="block mb-2">
            <span className="text-sm font-medium text-gray-700">
              {t("middle_name")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              name="middleName"
              value={user?.middleName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z]*$/.test(value)) {
                  setUser({ ...user, middleName: e.target.value });
                }
              }}
            />
          </div>
          <div className="block mb-2">
            <span className="text-sm font-medium text-gray-700">
              {t("last_name")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              value={user?.lastName}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z]*$/.test(value)) {
                  setUser({ ...user, lastName: e.target.value });
                }
              }}
              name="lastName"
            />
            {errors && errors.lastName && (
              <span className="text-sm text-red-500 leading-0">
                {errors.lastName}
              </span>
            )}
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("phone")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              value={user?.phone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\+?[0-9]*$/.test(value)) {
                  setUser({ ...user, phone: e.target.value });
                }
              }}
              name="phone"
              maxLength={10}
            />
            {errors && errors.phone && (
              <span className="text-sm text-red-500 leading-0">
                {errors.phone}
              </span>
            )}
          </div>

          <div className="block mb-2">
            <span className="text-sm font-medium text-gray-700">
              {t("email")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              name="email"
              value={user?.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            {errors && errors.email && (
              <span className="text-sm text-red-500 leading-0">
                {errors.email}
              </span>
            )}
          </div>

          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("degree")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              name="degree"
              value={user?.degree}
              onChange={(e) => setUser({ ...user, degree: e.target.value })}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("registration_number")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              name="registrationNumber"
              value={user?.registrationNumber}
              onChange={(e) =>
                setUser({ ...user, registrationNumber: e.target.value })
              }
            />
          </div>

          <div className="block mb-2">
            <span className="text-sm font-medium text-gray-700">
              {t("address")}
            </span>
            <input
              type="text"
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
              name="address"
              value={user?.address}
              onChange={(e) => setUser({ ...user, address: e.target.value })}
            />
          </div>
          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {t("role")}
            </span>
            <div className="mt-1">
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                onChange={handleRoleChange}
                value={user.roles}
              >
                {roles.map((role: any) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </div>
            {errors && errors.roles && (
              <span className="text-sm text-red-500 leading-0">
                {errors.roles}
              </span>
            )}
          </div>

          <div className="block">
            <span className="text-sm font-medium text-gray-700">
              {"Select Default Visit Type"}
            </span>
            <div className="mt-1">
              <Select
                style={{ width: "100%" }}
                onChange={handleClinicVisitTypeChange}
                value={user.defaultClinicVisitTypeId}
              >
                {clinicVisitTypes.map((clinicVisitType: any) => (
                  <Option key={clinicVisitType.id} value={clinicVisitType.id}>
                    {clinicVisitType.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-5">
        <button
          onClick={onSave}
          type="submit"
          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium border border-transparent rounded-md shadow-sm text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:w-auto sm:text-sm"
        >
          {userId ? t("update") : t("submit")}
        </button>
      </div>
    </div>
  );
};
export default AddUser;
