import { Upload, UploadProps, message } from "antd";
import { Visit } from "../../interfaces/Visit";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { saveVisitFiles } from "../../apis/visit";
import {
  changeVisitData,
  UpdateVisitField,
} from "../../features/visit/visitSlice";
import { useAppDispatch } from "../../hooks";

type VisitFilesProps = {
  visit: Visit;
};
const VisitFiles: React.FC<VisitFilesProps> = ({ visit }) => {
  const [loading, setLoading] = useState(false);
  const [, setVisitFile] = useState<any>();
  const [fileUrl, setFileUrl] = useState<string | null>();
  const dispatch = useAppDispatch();

  const UploadFileButton = () => (
    <div className="cursor-pointer">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8, width: 100 }}>Upload</div>
    </div>
  );
  const customFileSetter = (arg: any) => {
    setVisitFile(arg.file);
  };

  const beforeUploadingFile = (file: RcFile) => {
    let isValid = true;
    const isPDF = file.type === "application/pdf";
    const isPNG = file.type === "image/png";
    const isJPG = file.type === "image/jpeg";

    const isAcceptedFileType = isPDF || isPNG || isJPG;

    if (!isAcceptedFileType) {
      isValid = false;

      message.error("You can only upload PDF, PNG, or JPG files!");
    }

    if (!isValid) {
      return Upload.LIST_IGNORE;
    }
    return isAcceptedFileType;
  };

  const handleFileChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setLoading(true);
    setVisitFile(info.file);
    uploadReports(info.file.originFileObj as RcFile);
    setFileUrl(info.file.name);
    setLoading(false);
  };

  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const uploadReports = (file: any) => {
    saveVisitFiles({ file: file })
      .then((res: any) => {
        const files = [...visit.visitFiles, res.url];
        updateField("visitFiles", files);
        message.success("Files uploaded successfully!");
      })
      .catch((err) => {
        message.error("Failed to upload files!");
      });
  };

  return (
    <div className="relative grid items-center grid-cols-2 gap-2 py-3 bg-white rounded-lg lg:gap-4 lg:px-6 lg:py-5 lg:border lg:border-gray-300 lg:shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
      <div className="block">
        <span className="text-sm font-medium text-gray-700">Reports</span>

        <div className="mt-2 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-gray-200">
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUploadingFile}
              onChange={handleFileChange}
              customRequest={customFileSetter}
              multiple={true}
            >
              <UploadFileButton />
            </Upload>
          </div>
        </div>
      </div>
      {visit.visitFiles && visit.visitFiles.length > 0 && (
        <div className="flex-grow block col-span-2">
          <span className="text-sm font-medium text-gray-700">
          </span>
          <div className="grid grid-cols-2 mt-2">
            <ul>
              {visit.visitFiles &&
                visit.visitFiles?.map((file: any, index: number) => (
                  <li className="m-1 text-sm text-gray-500" key={index}>
                    {file && file.name ? file.name :file}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisitFiles;
