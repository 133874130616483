import React, { useEffect, useRef, useState } from "react";
import { PrescriptionDetails } from "../../interfaces/Visit";
import { formatString } from "../../utils/StringUtil";
import { User } from "../../interfaces/User";
import whatsAppIcon from "../../assets/images/icons/whatsapp.svg";
import html2canvas from "html2canvas";
import { sendPrescription } from "../../apis/visit";

type PrescriptionProps = {
  preview?: boolean;
  prescriptionDetails: PrescriptionDetails;
};
const Prescription: React.FC<PrescriptionProps> = ({
  preview,
  prescriptionDetails,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [instructions, setInstructions] = useState<Array<string>>([]);
  const user: User | null = JSON.parse(
    window.localStorage.getItem("user") || "{}"
  );

  useEffect(() => {
    if (prescriptionDetails.advice) {
      const adviceJson = JSON.parse(prescriptionDetails.advice);
      const data = adviceJson.blocks.map((i: Record<string, string>) => i.text);
      setInstructions(data);
    }
  }, [prescriptionDetails]);

  const takeScreenshot = () => {
    if (divRef.current) {
      html2canvas(divRef.current).then((canvas) => {
        // You can append the screenshot to the DOM
        document.body.appendChild(canvas);

        // Or convert the canvas to an image data URL
        const imgData = canvas.toDataURL("image/png");
        const blob = dataURLToBlob(imgData);
        const formData = new FormData();
        formData.append("image", blob, "image.png");
        try {
          sendPrescription(formData, prescriptionDetails.visitId);
        } catch (error) {
          throw new Error("Error while sending prescription");
        }
      });
    }
  };

  const dataURLToBlob = (dataURL: string): Blob => {
    const [header, data] = dataURL.split(",");
    const mimeMatch = header.match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "application/octet-stream"; //
    const binaryString = atob(data);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mime });
  };

  const handlePrint = () => {
    if (divRef.current) {
      const printContent = divRef.current.innerHTML;
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(`
      <html><head><title>Print</title><style>body{font-family:Arial,sans-serif;padding:20px;margin:0}.text-base{font-size:1rem}.text-sm{font-size:.875rem}.font-medium{font-weight:500}.font-semibold{font-weight:600}.text-gray-500{color:#6b7280}.text-gray-900{color:#111827}.capitalize{text-transform:capitalize}.header,.body,.footer{padding:16px;border-bottom:2px solid #e5e7eb}.header .grid,.body .grid{display:grid;grid-template-columns:1fr 1fr}.grid-cols-4{display:grid;grid-template-columns:repeat(4,1fr)}.text-right{text-align:right}.text-center{text-align:center}table{width:100%;margin-top:8px;border-collapse:collapse}th,td{padding:8px;border:1px solid #e5e7eb;font-size:.875rem}th{font-weight:600}.mt-2{margin-top:8px}.py-2{padding:8px 0}.pt-2{padding-top:8px}.p-4{padding:16px}.pl-4{padding-left:16px}.w-12{width:48px}.col-span-1{grid-column:span 1/span 1}dd{margin:0}.flex-col{flex-direction:column}.flex{display:flex}.border{border:1px solid #e5e7eb}.border-b-2{border-bottom:1px solid #e5e7eb}</style></head><body onload="window.print();"><div class="border">${printContent}</div></body></html>`);
        printWindow.document.close();
      }
    }
  };

  return (
    <>
      {user ? (
        <>
          <div
            ref={divRef}
            className="flex flex-col max-w-4xl mx-auto bg-white border rounded-md prescription"
          >
            <div className="p-4 border-b-2 basis-1/6 header">
              <div className="grid grid-cols-2">
                <div className="flex flex-col">
                  <span className="text-lg font-medium capitalize">
                    {user?.activeClinic?.name}
                  </span>
                  <span className="text-base capitalize">
                    {user.activeClinic?.address}
                  </span>
                  <span className="flex gap-1 text-base capitalize">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="pt-1 size-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                    <span>Phone -</span>
                    {user.activeClinic?.phone}
                  </span>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-lg font-medium capitalize">
                    {user?.firstName} {user?.lastName}
                  </span>
                  <span className="text-base">{user.degree}</span>
                  <span className="text-base">
                    Reg. No. - {user.registrationNumber}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 p-4 border-b-2 basis-1/5">
              <div className="col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900 capitalize">
                  {prescriptionDetails?.patient?.firstName}{" "}
                  {prescriptionDetails?.patient?.lastName}
                </dd>
              </div>
              <div className="col-span-1">
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="mt-1 text-sm text-gray-900 capitalize">
                  {prescriptionDetails?.patient?.gender}
                </dd>
              </div>
              <div className="col-span-1">
                <dt className="text-sm font-medium text-gray-500">Age</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {prescriptionDetails?.patient?.age}
                </dd>
              </div>

              <div className="col-span-1">
                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {prescriptionDetails?.patient?.phone}
                </dd>
              </div>
            </div>

            <div className="px-4 py-2 basis-1/2 body">
              <div className="flex flex-col">
                <img src="/rx.png" className="w-12" alt="" />
              </div>
              <div className="py-2">
                <span className="font-semibold">Symptoms: </span>
                {prescriptionDetails.symptoms.join(",")}
              </div>
              <div>
                {prescriptionDetails.finalDiagnosis ? (
                  <div>
                    <strong>Final Diagnosis: </strong>{" "}
                    {prescriptionDetails.finalDiagnosis}
                  </div>
                ) : prescriptionDetails.provisionalDiagnosis ? (
                  <div>
                    <strong>Provisional Diagnosis: </strong>{" "}
                    {prescriptionDetails.provisionalDiagnosis}
                  </div>
                ) : null}
              </div>
              {/* {JSON.stringify(prescriptionDetails)} */}
              {prescriptionDetails.medicines &&
                prescriptionDetails.medicines?.length > 0 && (
                  <>
                    <span className="font-semibold">Medicines: </span>
                    <table className="w-full mt-2 border">
                      <thead className="border ">
                        <th className="font-semibold">Name</th>
                        <th className="font-semibold">Dosage</th>
                        <th className="font-semibold text-right">Notes</th>
                      </thead>
                      <tbody>
                        {prescriptionDetails.medicines?.map(
                          (medicine, index) => (
                            <tr className="border-b">
                              <td className="capitalize">
                                {index + 1}
                                {") "}
                                {medicine.instructions.name}
                              </td>
                              <td>
                                {medicine.instructions.morning} -{" "}
                                {medicine.instructions.afternoon} -{" "}
                                {medicine.instructions.night}
                                <br />
                                {formatString(medicine.instructions.meal)}
                              </td>
                              <td className="text-right">
                                {medicine.instructions.duration}{" "}
                                <span className="capitalize ">
                                  {medicine.instructions.durationPeriod}
                                  <br />
                                  {medicine.instructions.note}
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              {instructions.length > 0 && (
                <div className="py-2 mt-2 ">
                  <span className="font-semibold">Advice: </span>
                  <ol className="pl-4 list-decimal">
                    {instructions.map((i) => (
                      <li>{i}</li>
                    ))}
                  </ol>
                </div>
              )}
              <div className="grid grid-cols-1 p-2 sm:grid-cols-4"></div>
            </div>
            <div className="mt-auto text-center basis-1/5 footer">
              <p className="p-2 text-xs">
                This is digitally generated, requires no signature
              </p>
            </div>
          </div>
          <div className="flex justify-end gap-2 pt-2">
            <button
              onClick={handlePrint}
              className="px-3 py-1.5 text-white rounded-md bg-primary"
            >
              Print
            </button>
            <button
              onClick={() => takeScreenshot()}
              className="px-3 py-1.5 text-black rounded-md border flex items-center gap-2"
            >
              <span>Send on WhatsApp</span>
              <span>
                <img alt="whatsapp" src={whatsAppIcon} className="w-6 h-6" />
              </span>
            </button>
          </div>
        </>
      ) : (
        <div className="flex justify-center text-center">
          <span className="pt-20">Loading ...</span>
        </div>
      )}
    </>
  );
};

export default Prescription;
