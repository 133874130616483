import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import ChangePassword from "../components/ChangePassword";
import { getUser, getUserRoles, updateUser } from "../apis/user";
import { useParams } from "react-router-dom";
import { useNotification } from "../NotificationContext";
import { Select } from "antd";
import { Role } from "../interfaces/User";

const Profile = () => {
  const [changePasswordModalOpen, setChangePasswordVisibility] =
    useState<boolean>(false);
  const [user, setUser] = useState<any>({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    roles: [],
  });
  const { userId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [editableUser, setEditableUser] = useState<any>({});
  const { showNotification } = useNotification();
  const [roles, setRoles] = useState<Array<Role>>([]);

  const Option = Select;

  useEffect(() => {
    fetchRoles();
    if (userId) {
      fetchUser(userId);
    }
  }, [userId]);

  const handleCloseModal = () => {
    setChangePasswordVisibility(false);
  };

  const fetchUser = (id: any) => {
    getUser(id).then((res: any) => {
      setUser(res.data);
      setEditableUser(res.data);
    });
  };

  const fetchRoles = () => {
    getUserRoles().then((res: any) => {
      setRoles(res.data);
    });
  };

  const handleInputChange = (field: string, value: string) => {
    setEditableUser((prev: any) => ({ ...prev, [field]: value }));
  };

  const handleUpdate = () => {
    updateUser(editableUser)
      .then((res) => {
        window.localStorage.setItem("user", JSON.stringify(editableUser));
        setUser(editableUser);
        setIsEditing(false);
        showNotification(
          "success",
          "Profile updated successfully",
          "",
          "topRight"
        );
      })
      .catch((err: any) => {
        showNotification("error", err.response.data.message, "", "topRight");
      });
      fetchUser(userId);
  };

  const handleRoleChange = (roles: any) => {
    setEditableUser({
      ...editableUser,
      roles,
    });
  };

  return (
    <div className="px-4 py-5 bg-white border rounded-md md:px-8 md:py-8">
      <div className="lg:flex sm:items-center justify-between">
        <div className="px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Your Profile
          </h3>
          <p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">
            Personal details
          </p>
        </div>
        <div className=" ">
          {!isEditing && (
            <button
              className="px-4 py-2 text-sm text-white bg-primary rounded-md"
              onClick={() => setIsEditing(true)}
            >
              Edit Profile
            </button>
          )}
        </div>
      </div>
      <div className="mt-6 border-t border-gray-200">
        <dl className="ml-6 divide-y divide-gray-200">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {/* {user?.firstName + " " + user?.lastName} */}
              {isEditing ? (
                <input
                  type="text"
                  className="block w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={`${editableUser.firstName || ""} ${
                    editableUser.lastName || ""
                  }`}
                  onChange={(e) => {
                    const fullName = e.target.value;
                    const [firstName, ...rest] = fullName.split(" ");
                    handleInputChange("firstName", firstName); // Update first name
                    handleInputChange("lastName", rest.join(" ")); // Update last name
                  }}
                />
              ) : (
                [user?.firstName + " " + user?.lastName]
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Phone
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {/* {user?.phone} */}

              {isEditing ? (
                <input
                  type="text"
                  className="block w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={editableUser.phone || ""}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                />
              ) : (
                [user?.phone]
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Email
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {/* {user?.email} */}
              {isEditing ? (
                <input
                  type="text"
                  className="block w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={editableUser.email || ""}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                />
              ) : (
                [user?.email]
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Role
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {/* {user?.role} */}
              {isEditing ? (
            <div className="mt-1">

                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  onChange={handleRoleChange}
                  value={editableUser.roles}
                >
                  {roles.map((role: any) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
                </div>
              ) : (
                [
                  user?.roles && user.roles.length > 0
                    ? user.roles.join(", ")
                    : "-",
                ]
              )}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              Address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 capitalize sm:col-span-2 sm:mt-0">
              {/* {user?.address} */}
              {isEditing ? (
                <input
                  type="text"
                  className="block w-2/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={editableUser.address || ""}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                />
              ) : (
                [user?.address]
              )}
            </dd>
          </div>
        </dl>

        <div
          className="px-4 py-6 border-t border-gray-200"
          onClick={() => setChangePasswordVisibility(true)}
        >
          <dt>
            <div className="pb-4 cursor-pointer">
              <span>Change Your Password</span>
              <p className="text-sm text-gray-500">
                Click here to change password .
              </p>
            </div>
          </dt>
        </div>
        <div className="flex justify-end px-4 py-6 border-t border-gray-200">
          {isEditing && (
            <>
              <button
                className="px-4 py-2 mr-2 text-sm font-semibold text-gray-900 bg-gray-200 rounded-md"
                onClick={() => {
                  setIsEditing(false);
                  setEditableUser(user);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-sm text-white bg-primary rounded-md"
                onClick={() => {
                  handleUpdate();
                }}
              >
                Update
              </button>
            </>
          )}
        </div>
      </div>
      {changePasswordModalOpen && (
        <Modal
          modalContent={
            <ChangePassword closeModal={() => handleCloseModal()} />
          }
        />
      )}
    </div>
  );
};
export default Profile;
