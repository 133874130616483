import React, { useContext, useEffect, useState } from 'react';
import { addSymptom, getSymptom, updateSymptom } from '../../apis/symptom';
import { useNotification } from '../../NotificationContext';
import { LanguageContext } from '../../providers/LanguageProvider';

const AddSymptom: React.FC<{ closeModal: () => void; editableId: any }> = ({
  closeModal,
  editableId,
}) => {
  const defalutSymptom = {
    name: '',
  };
  const [symptom, setSymptom] = useState(defalutSymptom);
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>();
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    if (editableId) {
      fetchSymptom(editableId);
    }
  }, [editableId]);
  
  const fetchSymptom = (id: number) => {
    getSymptom(id).then((res: any) => {
      setSymptom(res.data);
    });
  };

  const saveSymptom = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    addSymptom({ ...symptom }).then((res) => {
      showNotification('success', 'Symptom added successfully', '', 'topRight');
      closeModal();
    });
  };
  const cancel = () => {
    closeModal();
  };

  const updateSymptomData = () => {
    updateSymptom({
      id: editableId,
      ...symptom,
    }).then((res) => {
      showNotification(
        'success',
        'Symptom Updated successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };
  const onSave = () => {
    if (editableId) {
      updateSymptomData();
    } else {
      saveSymptom();
    }
  };
  const validateForm = () => {
    const validationErrors: any = {};
    if (symptom?.name === '') {
      validationErrors.name = '* Name is required';
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <div className=''>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('name')}
        </label>
        <div className='mt-1'>
          <input
            id='about'
            name='about'
            type='text'
            value={symptom?.name}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z]*$/.test(value)) {
               setSymptom({ ...symptom, name: e.target.value })
              }
            }}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.name && (
          <span className='text-sm text-red-500 leading-0'>{errors.name}</span>
        )}
      </div>

      <div className='flex items-center justify-start py-4 gap-x-6 border-gray-900/10 '>
        <button
          onClick={() => onSave()}
          type='submit'
          className='px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark'
        >
          {t('save')}
        </button>
        <button
          onClick={() => cancel()}
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};
export default AddSymptom;
