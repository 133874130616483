import { useState } from "react";
import { useNotification } from "../../NotificationContext";
import { getVisit, saveVisit, updateVisit } from "../../apis/visit";
import { format } from "date-fns";
import { checkDoctorAvailability } from "../../apis/event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Visit } from "../../interfaces/Visit";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";

type FollowUpVisitType = Omit<
  Visit,
  "visitStatusId" | "clinicVisitType" | "advice" | "amountPaid" | "visitFiles"
>;

const AddFollowUpVisit: React.FC<{
  closeModal: () => void;
  patientId: any;
  userId: number;
  parentId: any;
  clinicVisitTypeId: any;
  clinicVisitTypeLayoutId: any;
  chiefComplaint: any;
}> = ({
  closeModal,
  patientId,
  userId,
  parentId,
  clinicVisitTypeId,
  clinicVisitTypeLayoutId,
  chiefComplaint,
}) => {
  const [visit, setVisit] = useState<FollowUpVisitType>({
    userId,
    isInpatient: true,
    patientId: patientId,
    clinicVisitTypeId: clinicVisitTypeId,
    parentId: parentId,
    clinicVisitTypeLayoutId: clinicVisitTypeLayoutId,
    meta: {
      height: "",
      weight: "",
    },
    fee: 0,
    discount: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
    date: format(new Date(), "yyy-MM-dd HH:mm").toString(),
    chiefComplaint: chiefComplaint,
    visitStatus: "",
  });
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>();
  const [visitId, setVisitId] = useState<any>();
  const [isPostponeVisit, setIsPostponeVisit] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const validationErrors: any = {};

    if (!visit?.date || isNaN(new Date(visit.date).getTime())) {
      validationErrors.date = "* date is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const createFollowUpVisit = async () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveVisit({
      ...visit,
      date: new Date(visit.date).toISOString(),
      is_scheduled: true,
      chiefComplaint,
    })
      .then(() => {
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        closeModal();
      })
      .catch((err) => {
        setErrors(err.response.data.errors);

        const message = err?.response?.data?.message;
        if (message) {
          const existingVisitId = message.split("|")[1];
          if (existingVisitId) {
            setVisitId(existingVisitId);
            setIsPostponeVisit(true);
          }
        } else {
          showNotification(
            "error",
            "An error occurred while saving the visit",
            "",
            "topRight"
          );
        }
      });
  };

  const handleDateSelect = (date: any) => {
    setVisit({
      ...visit,
      date: format(new Date(date), "yyy-MM-dd HH:mm").toString(),
    });
  };

  const handleColor = (time: Date) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  const handlePostponeVisit = async () => {
    let serialNumber;
    await getVisit(visitId).then((res: any) => {
      serialNumber = res.serialNumber;
    });

    try {
      const res: any = await updateVisit({
        ...visit,
        serialNumber: serialNumber,
        date: new Date(visit.date).toISOString(),
        id: visitId,
      });
      showNotification("success", "Visit updated successfully", "", "topRight");
      closeModal();
    } catch (err: any) {
      const message =
        err?.data?.message || "An error occurred while updating the visit";
      showNotification("error", message, "", "topRight");
    }
  };

  const createNewFollowupVisit = async () => {
    saveVisit({
      ...visit,
      date: new Date(visit.date).toISOString(),
      is_scheduled: false,
    })
      .then((res: any) => {
        showNotification(
          "success",
          "Appointment Added successfully",
          "",
          "topRight"
        );
        closeModal();

        navigate("/visits");
      })
      .catch((err) => {});
  };

  return (
    <div className="">
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Date
        </label>
        <div className="mt-1">
          <DatePicker
            className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            showTimeSelect
            selected={new Date(visit.date)}
            onChange={(date) => handleDateSelect(date)}
            popperPlacement="bottom-start"
            portalId="root-panel"
            dateFormat="dd/MM/yyyy hh:mm a"
            timeClassName={handleColor}
          />
          {errors && errors.date && (
            <span className="text-sm text-red-500 leading-0">
              {errors.date}
            </span>
          )}
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => createFollowUpVisit()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Save
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
      </div>
      {isPostponeVisit && (
        <ConfirmationModal
          message="You have already scheduled this visit with the same doctor on same date. Do you want to postpone this visit?"
          onClose={() => setIsPostponeVisit(false)}
          onSubmit={() => {
            handlePostponeVisit();
            setIsPostponeVisit(false);
          }}
          okLabel="Yes"
          cancelLabel="Cancel"
          noLabel="No"
          onNo={() => {
            setIsPostponeVisit(false);
            createNewFollowupVisit();
          }}
        ></ConfirmationModal>
      )}
    </div>
  );
};
export default AddFollowUpVisit;
