import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { LayoutComponent } from "../../interfaces/LayoutComponent";
import { classNames } from "../../utils/CssUtil";
import DynamicComponent from "../layoutDesign/DynamicComponent";
import { Visit } from "../../interfaces/Visit";
import "./CustomPage.css";
import { useState } from "react";
import Modal from "../../components/Modal";
import AddMedicine from "../medicines/AddMedicine";

type CustomPageProps = {
  gridItems: LayoutComponent[];
  layout: Layout[];
  layoutKey: string;
  visit: Visit;
  onSetValue: (e: any, LayoutComponent: LayoutComponent, index: number) => void;
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const CustomPage: React.FC<CustomPageProps> = ({
  gridItems,
  layout,
  onSetValue,
  layoutKey,
  visit,
}) => {
  const [isMedicineModalOpen, setIsMedicineModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleEvent = (event: string, payload: any) => {
    if (event === "create-medicine") {
      setSearchTerm(payload);
      setIsMedicineModalOpen(true);
    }
  };
  return (
    <div
      className="custom-page h-[calc(100vh-273px)]"
      key={layoutKey}
      style={{}}
    >
      <ResponsiveGridLayout
        autoSize={true}
        className="layout"
        layouts={{ lg: layout }}
        rowHeight={40}
        cols={{ lg: 6, md: 6, sm: 6, xs: 4, xxs: 2 }}
        isDraggable={false}
        isResizable={false}
        onLayoutChange={() => {}}
      >
        {gridItems.map((gridItem: any, index) => {
          return (
            <div
              className={classNames(
                gridItem.component === "FasciaChart"
                  ? "border-0 bg-transperant"
                  : "bg-white   border-solid box-border",
                "p-2"
              )}
              key={gridItem.id}
            >
              <DynamicComponent
                key={gridItem.id}
                gridItem={gridItem}
                setValue={(e: any) => onSetValue(e, gridItem, index)}
                visit={visit}
                onEvent={handleEvent}
              />
            </div>
          );
        })}
      </ResponsiveGridLayout>
      {isMedicineModalOpen && (
        <Modal
          modalContent={
            <AddMedicine
              text={searchTerm}
              editableId={null}
              closeModal={() => setIsMedicineModalOpen(false)}
            />
          }
        />
      )}
    </div>
  );
};

export default CustomPage;
