import React, { useEffect, useState } from "react";
import { getServices } from "../../apis/service";
import {
  changeVisitData,
  selectVisit,
  UpdateVisitField,
} from "../../features/visit/visitSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Service } from "../../interfaces/Service";
import {
  PrescriptionDetails,
  Visit,
  VisitService,
} from "../../interfaces/Visit";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import Modal from "../../components/Modal";
import Prescription from "./Prescription";
import MedicineSelect from "./MedicineSelect";
import MedicineInstructions from "./MedicineInstructions";
import AddMedicine from "../medicines/AddMedicine";
import ServiceSelect from "./ServiceSelect";
import AddService from "../services/AddService";

const Treatment: React.FC<{
  preview?: boolean;
}> = ({ preview = false }) => {
  const visit = useAppSelector(selectVisit);
  const dispatch = useAppDispatch();
  const [services, setServices] = useState<Array<Service>>([]);
  const [selectedServices, setSelectedServices] = useState<Array<any>>([]);
  const [isPrescriptionVisible, setIsPrescriptionVisible] = useState(false);
  const [isMedicineModalOpen, setIsMedicineModalOpen] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [serviceText, setServiceText] = useState("");
  const [medicineText, setMedicineText] = useState("");

  const [prescriptionDetails, setPrescriptionDetails] =
    useState<PrescriptionDetails>();

  const [adviceEditorState, setAdviceEditorState] = useState(
    EditorState?.createEmpty()
  );

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (visit && visit.services.length > 0) {
      const selectedServices = visit.services.map((i) => i.id);
      setSelectedServices(selectedServices);
    }
  }, [visit]);

  useEffect(() => {
    if (visit && visit.advice) {
      convertTextToEditor(visit?.advice, setAdviceEditorState);
    }
  }, []);

  const convertTextToEditor = (
    text: any,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    if (text) {
      const contentState = convertFromRaw(JSON.parse(text));
      const initialEditorState = EditorState?.createWithContent(contentState);
      setState(initialEditorState);
    }
  };

  const updateField = (fieldName: keyof Visit, value: any) => {
    const payload: UpdateVisitField = { fieldName, value };
    dispatch(changeVisitData(payload));
  };

  const fetchServices = () => {
    getServices({}).then((res: any) => {
      setServices(res.data.data);
    });
  };

  const onEditorStateChange = (
    editorState: EditorState,
    field: keyof Visit,
    setState: React.Dispatch<React.SetStateAction<EditorState>>
  ) => {
    setState(editorState);
    const contentState = editorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    updateField(field, contentStateString);
  };

  const showMedicineModal = (value: string) => {
    setMedicineText(value);
    setIsMedicineModalOpen(true);
  };

  const showServiceModal = (value: string) => {
    setServiceText(value);
    setIsServiceModalOpen(true);
  };

  return (
    <div>
      <div className="grid gap-4 p-4 lg:grid-cols-2">
        <div className="block ">
          <span className="text-sm font-medium text-gray-700">
            Select Medicine
          </span>
          <MedicineSelect
            visit={visit}
            onMedicineCreate={(value) => showMedicineModal(value)}
          />
        </div>
        <div className="block ">
          <span className="text-sm font-medium text-gray-700">Advice</span>

          <Editor
            editorState={adviceEditorState}
            toolbarClassName="border border-gray-300 rounded-md"
            wrapperClassName="h-auto"
            editorClassName="h-full border border-gray-300 rounded-md p-2 "
            onEditorStateChange={(editorState) =>
              onEditorStateChange(editorState, "advice", setAdviceEditorState)
            }
            toolbar={{
              options: ["inline", "list", "blockType"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
            }}
          />
        </div>
      </div>

      <MedicineInstructions visit={visit} />

      {isPrescriptionVisible && prescriptionDetails && (
        <Modal
          width="max-w-2xl"
          title="Prescription Preview"
          onClose={() => setIsPrescriptionVisible(false)}
          modalContent={
            <Prescription preview prescriptionDetails={prescriptionDetails} />
          }
        />
      )}

      {isMedicineModalOpen && (
        <Modal
          modalContent={
            <AddMedicine
              text={medicineText}
              editableId={null}
              closeModal={() => setIsMedicineModalOpen(false)}
            />
          }
        />
      )}
      {isServiceModalOpen && (
        <Modal
          modalContent={
            <AddService
              text={serviceText}
              editableId={null}
              closeModal={() => setIsServiceModalOpen(false)}
            />
          }
        />
      )}
    </div>
  );
};

export default Treatment;
