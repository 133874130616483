import api from "../middlware/api";
import { camelToSnake, snakeCase } from "../utils/CamelToSnake";
import { BASE_URL } from "../constants";
import { transformVisit } from "../utils/TransformVisit";
import { AxiosResponse } from "axios";
import { Option } from "../interfaces/MultiSelectTypes";
import ServiceList from "../views/services/ServiceList";
import { getServices } from "./service";

//get visit list
export const getVisits = (params: any) => {
  return new Promise((resolve, reject) => {
    let query = new URLSearchParams();
    for (let key in params) {
      query.set(key, params[key]);
    }
    api
      .get(`${BASE_URL}/api/v1/visits?${query.toString()}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//patch api to change visit status
export const changeVisitStatusById = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .patch(`${BASE_URL}/api/v1/visits/${payload.id}`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveVisit = async (payload: any) => {
  const defaultServices = await getServices({ 'is_default_selected': true });
  return new Promise((resolve, reject) => {
    payload.symptoms = payload.symptoms && payload.symptoms.length > 0 ? payload.symptoms.map((i: Option) => i.value) : [];
    payload.finalDiagnosis = payload.finalDiagnosis && payload.finalDiagnosis.length > 0 ? payload.finalDiagnosis.map((i: Option) => i.value) : [];
    payload.provisionalDiagnosis = payload.provisionalDiagnosis && payload.provisionalDiagnosis.length > 0 ? payload.provisionalDiagnosis.map(
      (i: Option) => i.value
    ) : [];
    payload.services = defaultServices.data.data;
    api
      .post(`${BASE_URL}/api/v1/visits`, camelToSnake(payload))
      .then((res) => {
        const result = transformVisit(res.data.data);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateVisit = (payload: any) => {
  const updatedPayload = {
    ...payload,
    symptoms: payload.symptoms && payload.symptoms.length > 0
      ? payload.symptoms.map((i: Option) => i.value)
      : [],
    finalDiagnosis: payload.finalDiagnosis && payload.finalDiagnosis.length > 0
      ? payload.finalDiagnosis.map((i: Option) => i.value)
      : [],
    provisionalDiagnosis: payload.provisionalDiagnosis && payload.provisionalDiagnosis.length > 0
      ? payload.provisionalDiagnosis.map((i: Option) => i.value)
      : [],

  }
  // return;
  return new Promise((resolve, reject) => {

    api
      .post(`${BASE_URL}/api/v1/visits/${updatedPayload.id}`, camelToSnake(updatedPayload))
      .then((res) => {
        const result = transformVisit(res.data.data);
        resolve(result);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getVisit = async (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/visits/${id}`)
      .then((res) => {
        const result = transformVisit(res.data.data);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// -------------------get previous visits-------------

export const getPreviousVisits = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/previous-visits/${payload}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// -------------------get completed visits-------------getClinicVisitTypes

export const getCompletedVisits = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/completed-visits/${payload}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/*-----------------visit Types----------------------*/
export const getClinicVisitTypes = (): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/clinic-visit-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// -------------------save layout in visit type layouts-------------

export const saveVisitTypeLayout = (
  clinicVisitTypeId: number,
  payload: any
) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${BASE_URL}/api/v1/clinic-visit-type-layouts/${clinicVisitTypeId}`,
        camelToSnake(payload)
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//-----------------------get layout by visit type id-----------

export const getLayout = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/clinic-visit-types/${payload}/layout`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// // -------------------get latest layout record from clinic visit type layouts-------------

// export const getLatestVisitTypeLayout = (payload: any) => {
//   return new Promise((resolve, reject) => {
//     api
//       .get(
//         `${BASE_URL}/api/v1/clinic-visit-type-layouts/${payload.clinicVisitTypeId}`
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

//-----------------------get layout by visit type id-----------

export const getLayoutById = (id: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/clinic-visit-type-layouts/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPaymentSummary = (id: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/payment-summary/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// -------------------to get visit transactions-------------

export const getVisitTransactions = (id: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/visit-transactions-list/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// ---------------------get prescription by visit id-------------

export const getVisitPrescription = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/visits/${payload}/prescription`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const exportVisits = (params: any) => {
  // return new Promise((resolve, reject) => {
  let query = new URLSearchParams();
  for (let key in params) {
    query.set(key, params[key]);
  }
  return api.get(`${BASE_URL}/api/v1/export/visits?${query.toString()}`, {
    responseType: "blob",
  });
};

export const saveVisitFiles = (payload: any) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    let key: keyof any;
    for (key in payload) {
      formData.append(snakeCase(key), payload[key] as Blob);
    }
    api
      .post(`${BASE_URL}/api/v1/visit-files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getVisitFiles = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/visit-files`)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getVisitFile = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/visit-files/download`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const saveAppointment = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/appointment`, camelToSnake(payload))
      .then((res) => {
        const result = transformVisit(res.data.data);
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/*-----------------visit Type----------------------*/
export const getVisitType = (id: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/visit-types/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// -------------------save visit type-------------

export const saveVisitType = (payload: any) => {
  const formData = new FormData();
  let key: any;
  for (key in payload) {
    formData.append(snakeCase(key), payload[key] as Blob);
  }
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/visit-types`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/*-----------------update visit Type----------------------*/
export const updateVisitType = (payload: any) => {
  const formData = new FormData();
  let key: any;
  for (key in payload) {
    formData.append(snakeCase(key), payload[key] as Blob);
  }
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/visit-types/${payload.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get visit i.e. booked appointment
export const getBookedAppointment = (id: number) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/booked-appointment/${id}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//get default visit types
export const getVisitTypes = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`${BASE_URL}/api/v1/default-visit-types`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addClinicVisitType = (payload: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${BASE_URL}/api/v1/clinic-visit-types`, camelToSnake(payload))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const removeClinicVisitType = (payload: number) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`${BASE_URL}/api/v1/clinic/visit-types/${payload}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const sendPrescription = (payload: any, visitId: number) => {
  return api.post(`${BASE_URL}/api/v1/send-prescription/${visitId}`, payload);
};
