import React from "react";
import { Event } from "../../interfaces/Event";
import { format } from "date-fns";
import { classNames } from "../../utils/CssUtil";

const EventDetails: React.FC<{
  event: Event | null;
  closeModal: () => void;
}> = ({ event, closeModal }) => {
  return (
    <div className="">
      <dl className="grid grid-cols-1 sm:grid-cols-2">
        {event?.eventCategory.name === "appointment" && (
          <div className="px-4 pb-3 border-gray-100 sm:col-span-1 sm:px-0">
            <dt className="font-medium text-gray-900 text-sm/6">
              Patient name
            </dt>
            <dd className="mt-1 text-gray-900 capitalize text-sm/6 ">
              {event?.patientName}
            </dd>
          </div>
        )}
        {event?.eventCategory.name === "appointment" && (
          <div className="px-4 pb-3 border-gray-100 sm:col-span-1 sm:px-0">
            <dt className="font-medium text-gray-900 text-sm/6">Doctor Name</dt>

            <dd className="mt-1 text-gray-900 capitalize text-sm/6 ">
              {event?.user}
            </dd>
          </div>
        )}

        {!event?.isAppointment && (
          <div
            className={classNames(
              event?.isAppointment === true ? "border-t border-gray-100" : "",
              "px-4 pb-3 sm:col-span-1 sm:px-0"
            )}
          >
            <dt className="font-medium text-gray-900 text-sm/6">Title</dt>

            <dd className="mt-1 text-gray-700 capitalize text-sm/6 ">
              {event?.title}
            </dd>
          </div>
        )}

        <div
          className={classNames(
            event?.isAppointment === true
              ? "border-t border-gray-100 py-6"
              : "",
            "px-4 pb-3 sm:col-span-1 sm:px-0"
          )}
        >
          <dt className="font-medium text-gray-900 text-sm/6">Category</dt>

          <dd className="mt-1 text-gray-700 capitalize text-sm/6 ">
            <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-indigo-700 capitalize rounded-md bg-indigo-50 ring-1 ring-inset ring-indigo-500/10">
              {event?.eventCategory.name}
            </span>
          </dd>
        </div>

        <div className="px-4 py-6 border-t border-gray-100 sm:col-span-1 sm:px-0">
          <dt className="font-medium text-gray-900 text-sm/6">Recurrence</dt>

          <dd className="mt-1 text-gray-700 capitalize text-sm/6 ">
            {event?.isRecurring ? "Recurring" : "Not Recurring"}
          </dd>
        </div>

        <div className="px-4 py-6 border-t border-gray-100 sm:col-span-1 sm:px-0">
          <dt className="font-medium text-gray-900 text-sm/6">Start Time</dt>
          <dd className="mt-1 text-gray-700 text-sm/6 ">
            {event?.startDate &&
              format(new Date(event.startDate), "dd MMM, yyyy hh:mm a")}
            {/* {event?.status && (
              <span className="inline-flex items-center px-2 py-1 ml-2 text-xs font-medium text-indigo-700 capitalize rounded-md bg-indigo-50 ring-1 ring-inset ring-indigo-500/10">
                {event?.status}
              </span>
            )} */}
             <span className="ml-1">
                              {event?.status === "scheduled" && (
                                <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-pink-800 capitalize rounded-md bg-pink-100 ring-1 ring-inset ring-pink-500/10 dark:bg-pink-200 dark:text-pink-900">
                                  {event.status}
                                </span>
                              )}
                              {event?.status === "checked in" && (
                                <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-800 capitalize rounded-md bg-blue-100 ring-1 ring-inset ring-blue-500/10 dark:bg-blue-200 dark:text-blue-900">
                                  {event.status}
                                </span>
                              )}
                              {event?.status === "completed" && (
                                <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-800 capitalize rounded-md bg-green-100 ring-1 ring-inset ring-green-500/10 dark:bg-green-200 dark:text-green-900">
                                  {event.status}
                                </span>
                              )}
                              {event?.status === "cancelled" && (
                                <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-800 capitalize rounded-md bg-red-100 ring-1 ring-inset ring-red-500/10 dark:bg-red-200 dark:text-red-900">
                                  {event.status}
                                </span>
                              )}
                              {event?.status === "expired" && (
                                <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-800 capitalize rounded-md bg-gray-100 ring-1 ring-inset ring-gray-500/10 dark:bg-gray-200 dark:text-gray-900">
                                  {event.status}
                                </span>
                              )}
                            </span>
          </dd>
        </div>
        {event?.endDate && (
          <div className="px-4 py-6 border-t border-gray-100 sm:col-span-1 sm:px-0">
            <dt className="font-medium text-gray-900 text-sm/6">End Time</dt>
            <dd className="mt-1 text-gray-700 text-sm/6 ">
              {format(new Date(event.endDate), "dd MMM, yyyy hh:mm a")}
            </dd>
          </div>
        )}

        <div className="px-4 py-6 border-t border-gray-100 sm:col-span-2 sm:px-0">
          <dt className="font-medium text-gray-900 text-sm/6">Description</dt>
          <dd className="mt-1 text-gray-700 text-sm/6 capitalize">
            {event?.description}
          </dd>
        </div>
      </dl>
      <div className="flex items-center justify-end w-full py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => closeModal()}
          type="button"
          className="px-3 py-2 text-sm font-semibold text-gray-900 border rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EventDetails;
