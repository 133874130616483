import React, { useContext, useEffect, useState } from "react";
import { useNotification } from "../../NotificationContext";
import { getDisorder, saveDisorder, updateDisorder } from "../../apis/disorder";
import { Select } from "antd";
import { LanguageContext } from "../../providers/LanguageProvider";

const AddDisorder: React.FC<{ closeModal: () => void; editableId: any }> = ({
  closeModal,
  editableId,
}) => {
  const defaultDisorder = {
    name: "",
    type: "",
  };
  const [disorder, setDisorder] = useState(defaultDisorder);
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>();
  const { translate: t } = useContext(LanguageContext);

  const types = [
    {
      name: "Disease",
      value: "disease",
    },
    {
      name: "Allergy",
      value: "allergy",
    },
    {
      name: "Surgery",
      value: "surgery",
    },
  ];
  useEffect(() => {
    if (editableId) {
      fetchDisorder(editableId);
    }
  }, [editableId]);

  const fetchDisorder = (id: number) => {
    getDisorder(id).then((res: any) => {
      setDisorder(res.data.data);
    });
  };

  const onSave = () => {
    if (editableId) {
      updateDisorderData();
    } else {
      addDisorder();
    }
  };

  const addDisorder = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveDisorder({ ...disorder }).then((res) => {
      showNotification(
        "success",
        "Disorder added successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const updateDisorderData = () => {
    updateDisorder({
      id: editableId,
      ...disorder,
    }).then((res) => {
      showNotification(
        "success",
        "Disorder updated successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };
  const setType = (type: string) => {
    setDisorder({
      ...disorder,
      type,
    });
  };
  const cancel = () => {
    closeModal();
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (disorder?.name === "") {
      validationErrors.name = "* Name is required";
    }
    if (disorder?.type === "") {
      validationErrors.type = "* Type is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  return (
    <div className="">
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("name")}
        </label>
        <div className="mt-1">
          <input
            id="name"
            name="about"
            type="text"
            value={disorder?.name}
            onChange={(e) => setDisorder({ ...disorder, name: e.target.value })}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.name && (
          <span className="text-sm text-red-500 leading-0">{errors.name}</span>
        )}
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("type")}
        </label>
        <div className="mt-1">
          <Select
            style={{ width: "100%" }}
            onChange={setType}
            value={disorder.type}
            options={types}
          ></Select>
        </div>

        {errors && errors.type && (
          <span className="text-sm text-red-500 leading-0">{errors.type}</span>
        )}
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {/* {editableId ? 'Update' : 'Save'} */}
          {editableId ? t("update") : t("save")}
        </button>
        <button
          onClick={() => cancel()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};
export default AddDisorder;
