import React, { useContext, useEffect, useState } from "react";
import BasicProfile from "./BasicProfile";
import AdvanceProfile from "./AdvanceProfile";
import Summary from "./Summary";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PatientShimmer from "./PatientShimmer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  changePatient,
  changeStep,
  selectPatient,
  selectStep,
  setInitialPatient,
} from "../../features/patient/patientSlice";
import {
  validateEmail,
  validateGender,
  validatePhone,
} from "../../utils/Validation";
import { savePatient, updatePatient } from "../../apis/patient";
import { Patient } from "../../interfaces/Patient";
import { LanguageContext } from "../../providers/LanguageProvider";
import { useNotification } from "../../NotificationContext";
import axios from "axios";

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ");
}
const AddPatient: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState<Patient | null>(null);
  const patient = useAppSelector(selectPatient);
  const currentStep = useAppSelector(selectStep);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translate: t } = useContext(LanguageContext);
  const { showNotification } = useNotification();
  const location = useLocation();
  useEffect(() => {
    if (location && location?.pathname.includes("new")) {
      dispatch(setInitialPatient());
      dispatch(changeStep(1));
    }
    if (searchParams.get("step")) {
      const step = searchParams.get("step");
      if (step) {
        dispatch(changeStep(parseInt(step)));
      }
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValidate = () => {
    const newErrors = {} as Patient;
    for (const key in patient) {
      if (patient.hasOwnProperty(key)) {
        if (key === "firstName") {
          if (patient[key] === "") {
            newErrors.firstName = "First name is required!";
          }
        }
        if (key === "lastName") {
          if (patient[key] === "") {
            newErrors.lastName = "Last name is required!";
          }
        }
        if (key === "phone") {
          if (patient[key] === "") {
            newErrors.phone = "Phone number is required!";
          }
        }
        if (key === "phone") {
          if (patient[key] === "") {
            newErrors.phone = "Phone is required!";
          } else if (!validatePhone(patient[key] as string)) {
            newErrors.phone = "Phone number is invalid!";
          }
        }
        if (key === "email" && patient[key] !== "" && patient[key] !== null) {
          if (!validateEmail(patient[key] as string)) {
            newErrors.email = "Email is invalid";
          }
        }
        if (key === "gender") {
          if (!validateGender(patient[key] as string)) {
            newErrors.gender = "Gender is invalid!";
          }
        }
        if (key === "age" && patient[key] !== "" && patient[key] !== null) {
          const age = Number(patient[key]);
          if (age > 120) {
            newErrors.age = "Age should not be greater than 120 years";
          }
        }
      }
    }

    const hasErrors = Boolean(Object.keys(newErrors).length);
    return { hasErrors, errors: newErrors };
  };

  const handleNext = async () => {
    try {
      const { hasErrors, errors } = handleValidate();
      if (hasErrors) {
        setErrors(errors);
        return;
      }
      setErrors(null);
      if (patient.id) {
        //update patient
        const response: any = await updatePatient(patient);
        if (response && response?.status === 200) {
          dispatch(changePatient(response?.data?.data));
        }
        navigate(`/patients/${response.data.data.id}?step=${currentStep + 1}`);
      } else {
        //save patient
        // const response: any = await savePatient(patient);
        savePatient(patient)
          .then((response) => {
            if (response && response?.status === 200) {
              dispatch(changePatient(response.data.data));
            }
            navigate(
              `/patients/${response.data.data.id}?step=${currentStep + 1}`
            );
          })
          .catch((err) => {
            const { message } = err.response.data;
            if (message) {
              showNotification("error", "Error Occured", message, "topRight");
            } else if (err?.response?.data?.details?.message) {
              showNotification(
                "error",
                err?.response?.data?.details?.message,
                "",
                "topRight"
              );
            }
          });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        showNotification(
          "error",
          "Error Occured",
          error?.response?.data.details.message,
          "topRight"
        );
      }
    }
  };
  const handlePrevious = async () => {
    if (patient.id) {
      //update patient
      const response: any = await updatePatient(patient);
      if (response && response?.status === 200) {
        dispatch(changePatient(response?.data?.data));
      }
      navigate(`/patients/${response.data.data.id}?step=${currentStep - 1}`);
    }
  };
  return (
    <div className="flex flex-col flex-grow h-[88vh]">
      <div className="flex flex-col ">
        <nav aria-label="Progress">
          <ol className="bg-white border-2 border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
            <li className="relative md:flex md:flex-1">
              <div className="flex items-center w-full group">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full bg-primary group-hover:bg-hover">
                    {currentStep === 1 ? (
                      <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-white border-2 rounded-full border-primary">
                        <span className="text-primary">01</span>
                      </span>
                    ) : (
                      <svg
                        className="w-6 h-6 text-white"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                  <span
                    className={classNames(
                      currentStep === 1 ? "text-primary" : " text-gray-900",
                      "ml-4 text-sm font-medium"
                    )}
                  >
                    {t("basic_profile")}
                  </span>
                </span>
              </div>
              <div
                className="absolute top-0 right-0 hidden w-5 h-full md:block"
                aria-hidden="true"
              >
                <svg
                  className="w-full h-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>
            <li className="relative md:flex md:flex-1">
              <div
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                {currentStep > 2 ? (
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full bg-primary group-hover:bg-hover">
                    <svg
                      className="w-6 h-6 text-white"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                ) : currentStep === 2 ? (
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 rounded-full border-primary">
                    <span className="text-primary">02</span>
                  </span>
                ) : (
                  <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">
                      02
                    </span>
                  </span>
                )}

                <span
                  className={classNames(
                    currentStep === 2 ? "text-primary" : " text-gray-900",
                    "ml-4 text-sm font-medium"
                  )}
                >
                  {t("advance_profile")}
                </span>
              </div>
              <div
                className="absolute top-0 right-0 hidden w-5 h-full md:block"
                aria-hidden="true"
              >
                <svg
                  className="w-full h-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>
            <li className="relative md:flex md:flex-1">
              <div className="flex items-center group">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  {currentStep === 3 ? (
                    <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 rounded-full border-primary">
                      <span className="text-primary">03</span>
                    </span>
                  ) : (
                    currentStep < 3 && (
                      <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                        <span className="text-gray-500 group-hover:text-gray-900">
                          03
                        </span>
                      </span>
                    )
                  )}
                  <span
                    className={classNames(
                      currentStep === 3 ? "text-primary" : " text-gray-900",
                      "ml-4 text-sm font-medium"
                    )}
                  >
                    {t("summary")}
                  </span>
                </span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="flex bg-white flex-col border-2 lg:h-[65vh] border-t border-l border-r">
        {currentStep === 1 ? (
          <BasicProfile errors={errors} />
        ) : currentStep === 2 ? (
          <AdvanceProfile errors={errors} />
        ) : currentStep === 3 ? (
          <Summary errors={errors} />
        ) : (
          <PatientShimmer />
        )}
      </div>
      <footer className="px-4 py-4 bg-white footer">
        <div className="footer-content">
          <div className="flex flex-row-reverse">
            {currentStep === 3 && (
              <a
                href="/patients"
                className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t("go_to_list")}
              </a>
            )}
            {currentStep < 3 && (
              <button
                onClick={() => handleNext()}
                className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t("next")}
              </button>
            )}
            {currentStep > 1 && (
              <button
                onClick={() => handlePrevious()}
                className="px-6 py-2 mx-2 my-2 text-sm transition duration-150 ease-in-out rounded text-light bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {t("previous")}
              </button>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AddPatient;
