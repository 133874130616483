import { format, getDate, getHours, getMinutes } from "date-fns";
import React, { useState } from "react";
import { Event } from "../../interfaces/Event";
import { classNames } from "../../utils/CssUtil";
import { getFormattedHours } from "../../utils/DateUtil";
import { ListVisitItem } from "../../interfaces/Visit";
import { getVisit } from "../../apis/visit";

type CalendarDay = {
  date: Date;
  events: Array<Event>;
};

type WeekViewProps = {
  weekDays: Array<Date>;
  events: Array<Event>;
  startDateOfWeek: number;
  onDayClick: (day: CalendarDay) => void;
  onViewDetails: (event: Event) => void;
  onViewVisit: (visit: ListVisitItem, event: Event) => void;
};

const WeekView: React.FC<WeekViewProps> = ({
  weekDays,
  events,
  startDateOfWeek,
  onDayClick,
  onViewDetails,
  onViewVisit,
}) => {
  const [hourOffset] = useState(9);
  const workingHours = 12;
  const [weekViewHours] = useState(getFormattedHours(9, 20));

  const handleViewEvent = (event: Event) => {
    if (event.isAppointment && event.id) {
      getVisit(event.id).then((res: any) => {
        onViewVisit(res, event);
      });
    } else {
      onViewDetails(event);
    }
  };

  const appointmentWindow = parseInt(
    window.localStorage.getItem("appointmentWindow") ?? "15"
  );

  return (
    <div
      style={{ width: "165%" }}
      className="flex flex-col flex-none max-w-full sm:max-w-none md:max-w-full"
    >
      <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
        <div className="grid-cols-7 -mr-px text-sm leading-6 text-gray-500 border-r border-gray-100 divide-x divide-gray-100 sm:grid">
          <div className="col-end-1 w-14"></div>
          {weekDays &&
            weekDays.map((day: any, i: number) => (
              <div key={i} className="flex items-center justify-center py-3">
                <span
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDayClick(day);
                  }}
                >
                  {format(day.date, "E")}

                  <span className="items-center justify-center font-semibold text-gray-900">
                    {" " + format(day.date, "dd")}
                  </span>
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-auto">
        <div className="sticky left-0 z-10 flex-none bg-white w-14 ring-1 ring-gray-100"></div>
        <div className="grid flex-auto grid-cols-1 grid-rows-1">
          {/* <!-- Horizontal lines --> */}
          <div
            className="grid col-start-1 col-end-2 row-start-1 divide-y divide-gray-200"
            style={{
              gridTemplateRows: `repeat(${
                (24 - workingHours) * 2
              }, minmax(3.5rem, 1fr))`,
            }}
          >
            {weekViewHours &&
              weekViewHours.length > 0 &&
              weekViewHours.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="sticky z-20 text-xs leading-5 text-left text-gray-400 -ml-14">
                    <span className="ml-4">{item}</span>
                  </div>
                  <div></div>
                </React.Fragment>
              ))}
          </div>

          {/* <!-- Vertical lines --> */}
          <div className="hidden grid-cols-7 col-start-1 col-end-2 grid-rows-1 row-start-1 divide-x divide-gray-200 sm:grid sm:grid-cols-7">
            <div className="col-start-1 row-span-full"></div>
            <div className="col-start-2 row-span-full"></div>
            <div className="col-start-3 row-span-full"></div>
            <div className="col-start-4 row-span-full"></div>
            <div className="col-start-5 row-span-full"></div>
            <div className="col-start-6 row-span-full"></div>
            <div className="col-start-7 row-span-full"></div>
            <div className="w-8 col-start-8 row-span-full"></div>
          </div>

          {/* Events */}
          <ol
            className="grid grid-cols-1 col-start-1 col-end-2 row-start-1 sm:grid-cols-7 sm:pr-8"
            style={{
              gridTemplateRows: `repeat(${(24 - workingHours) * 12}, 1fr)`,
            }}
          >
            {events &&
              events.length > 0 &&
              events
                .sort(
                  (a: Event, b: Event) =>
                    new Date(b.startDate).getTime() -
                    new Date(a.startDate).getTime()
                )
                .map((event: Event, index: number) => (
                  <li
                    key={index}
                    className={classNames("relative flex")}
                    style={{
                      gridRow: `${
                        (getHours(new Date(event.startDate)) - hourOffset) *
                          12 +
                        1 +
                        Math.floor(getMinutes(new Date(event.startDate)) / 5)
                      } / span ${(appointmentWindow / 60) * 12}`,
                      gridColumnStart:
                        getDate(new Date(event.startDate)) -
                        getDate(startDateOfWeek) +
                        1,
                    }}
                  >
                    <button
                      // className="absolute flex flex-col p-2 overflow-y-auto text-xs leading-5 rounded-lg group inset-1 bg-blue-50 hover:bg-blue-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleViewEvent(event);
                      }}
                      className={classNames(
                        event?.status && event.status === "completed"
                          ? "bg-green-400"
                          : "bg-indigo-400",
                        "inline-block w-full px-2 py-0.5 text-xs text-white text-ellipsis capitalize truncate  rounded-md border cursor-pointer group-hover:text-indigo-600"
                      )}
                    >
                      <p className="order-1 font-semibold">
                        {format(new Date(event.startDate), "hh:mm a")}
                      </p>

                      <p className="order-2 font-semibold capitalize">
                        {event.patientName}
                      </p>
                      <p className=" group-hover:text-blue-700">
                        <span>{event.title}</span>
                      </p>
                    </button>
                  </li>
                ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default WeekView;
