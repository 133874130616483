import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { Visit } from "../../interfaces/Visit";
import { format } from "date-fns";

export interface VisitState {
  visit: Visit;
  currentStep: number;
}
export interface UpdateVisitField {
  fieldName: keyof Visit;
  value: string | number | Date | Array<any>;
}

export interface UpdateVisitMeta {
  fieldName: string;
  value: string;
}

const initialState: VisitState = {
  visit: {
    date: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
    chiefComplaint: "",
    // visitStatusId:1,
    visitStatus:"",
    patientId: null,
    parentId: null,
    serialNumber: "",
    isInpatient: false,
    clinicVisitTypeId: 1,
    clinicVisitTypeLayoutId: null,
    userId: null,
    meta: {
      layoutContent: {},
    },
    fee: 0,
    discount: 0,
    amountPaid: 0,
    medicines: [],
    services: [],
    symptoms: [],
    provisionalDiagnosis: [],
    finalDiagnosis: [],
    advice: "",
    clinicVisitType: {
      id: 1,
      clinicId: 0,
      visitTypeId: 0,
      visitType: {
        id: 1,
        name: "",
      },
    },
    visitFiles: [],
  },
  currentStep: 1,
};

export const visitSlice = createSlice({
  name: "visit",
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    changeVisitData: (state, action: PayloadAction<UpdateVisitField>) => {
      const { fieldName, value } = action.payload;
      (state.visit as any)[fieldName] = value;
    },
    changeVisitMeta: (state, action: PayloadAction<UpdateVisitMeta>) => {
      const { fieldName, value } = action.payload;
      (state.visit.meta as any)[fieldName] = value;
    },

    changeVisit: (state, action: PayloadAction<Visit>) => {
      state.visit = action.payload;
    },
    setDefaultVisit: (state) => {
      state.visit = initialState.visit;
    },
  },
});

export const {
  changeStep,
  changeVisitData,
  changeVisitMeta,
  changeVisit,
  setDefaultVisit,
} = visitSlice.actions;

export const selectStep = (state: RootState) => state.visitState.currentStep;
export const selectVisit = (state: RootState) => state.visitState.visit;

export default visitSlice.reducer;
