import React, { useContext, useEffect, useState } from 'react';
import { useNotification } from '../../NotificationContext';
import { Select } from 'antd';
import { ExpenseCategory } from '../../interfaces/ExpenseCategory';
import { getExpense, saveExpense, updateExpense } from '../../apis/expense';
import { getExpenseCategories } from '../../apis/expenseCategory';
import { LanguageContext } from '../../providers/LanguageProvider';
const AddExpense: React.FC<{
  closeModal: () => void;
  editableId: any;
}> = ({ closeModal, editableId }) => {
  const defaultExpense = {
    amount: '',
    paymentMode: '',
    paymentNote: '',
    expenseCategoryId: null,
    date: '',
  };
  const [expense, setExpense] = useState(defaultExpense);
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();
  const [expenseCategories, setExpenseCategories] = useState<
    Array<ExpenseCategory>
  >([]);
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);

  const paymentModes = [
    {
      name: 'Cash',
      value: 'cash',
    },

    {
      name: 'Cheque',
      value: 'cheque',
    },

    {
      name: 'Online-Transfer',
      value: 'online-transfer',
    },

    {
      name: 'UPI',
      value: 'upi',
    },
  ];

  useEffect(() => {
    fetchExpeneseCategories({});
    if (editableId) {
      fetchExpense(editableId);
    }
  }, [editableId]);

  const fetchExpense = (id: number) => {
    getExpense(id).then((res: any) => {
      setExpense(res.data);
    });
  };

  const fetchExpeneseCategories = (params: {}) => {
    getExpenseCategories(params).then((res: any) => {
      setExpenseCategories(res.data);
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (expense?.amount === '') {
      validationErrors.amount = '* amount is required';
    }
    if (expense?.paymentMode === '') {
      validationErrors.paymentMode = '* Payment mode is required';
    }

    if (expense?.paymentNote === '') {
      validationErrors.paymentNote = '* Payment note is required';
    }
    if (expense?.expenseCategoryId === null) {
      validationErrors.expenseCategoryId = '* Expense Category is required';
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const addExpense = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveExpense({
      ...expense,
    }).then((res: any) => {
      showNotification('success', 'Expense Added successfully', '', 'topRight');
      closeModal();
    });
  };

  const updateExpenseData = () => {
    updateExpense({
      ...expense,
      id: editableId,
    }).then((res) => {
      showNotification(
        'success',
        'Expense Updated successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateExpenseData();
    } else {
      addExpense();
    }
  };

  const setPaymentMode = (paymentMode: string) => {
    setExpense({
      ...expense,
      paymentMode,
    });
  };

  const handleExpenseChange = (expenseCategoryId: any) => {
    setExpense({
      ...expense,
      expenseCategoryId,
    });
  };

  return (
    <div className=''>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('amount')}
        </label>
        <div className='mt-1'>
          <input
            id='amount'
            name='amount'
            type='number'
            value={expense?.amount}
            onChange={(e) => setExpense({ ...expense, amount: e.target.value })}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.amount && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.amount}
          </span>
        )}
      </div>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('payment_mode')}
        </label>
        <div className='mt-1'>
          <Select
            style={{ width: '100%' }}
            onChange={setPaymentMode}
            value={expense.paymentMode}
            options={paymentModes}
          />
        </div>

        {errors && errors.paymentMode && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.paymentMode}
          </span>
        )}
      </div>

      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('select_expense_category')}
        </label>
        <div className='mt-1'>
          <Select
            style={{ width: '100%' }}
            onChange={handleExpenseChange}
            value={expense.expenseCategoryId}
          >
            {expenseCategories.map((expenseCategory) => (
              <Option key={expenseCategory.id} value={expenseCategory.id}>
                {expenseCategory.name}
              </Option>
            ))}
          </Select>
        </div>

        {errors && errors.expenseCategoryId && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.expenseCategoryId}
          </span>
        )}
      </div>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('payment_note')}
        </label>
        <div className='mt-1'>
          <input
            id='paymentNote'
            name='paymentNote'
            type='text'
            value={expense?.paymentNote}
            onChange={(e) =>
              setExpense({ ...expense, paymentNote: e.target.value })
            }
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.paymentNote && (
          <span className='text-sm text-red-500 leading-0'>
            {errors.paymentNote}
          </span>
        )}
      </div>

      <div className='block mb-3'>
        <span className='text-sm font-medium text-gray-700'> {t('date')}</span>

        <input
          type='date'
          id='registrationDate'
          name='date'
          value={expense?.date}
          onChange={(e) => setExpense({ ...expense, date: e.target.value })}
          className='block w-full mt-1 capitalize border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm'
        />
        {errors && errors.date && (
          <span className='text-sm text-red-500 leading-0'>{errors.date}</span>
        )}
      </div>

      <div className='flex items-center justify-start py-4 gap-x-6 border-gray-900/10 '>
        <button
          onClick={() => onSave()}
          type='submit'
          className='px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark'
        >
          {editableId ? t('update') : t('save')}
        </button>
        <button
          onClick={() => closeModal()}
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default AddExpense;
