import React, { useEffect, useState } from "react";
import {
  getPaymentSummary,
  getVisitFile,
  getVisitPrescription,
} from "../../apis/visit";
import { BASE_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { json } from "stream/consumers";

// Reusable Component for Displaying List of Values
const ListDisplay: React.FC<{ title: string; items: string[] }> = ({
  title,
  items,
}) => {
  return (
    <div className="sm:col-span-1">
      <dt className="text-base font-medium text-gray-500">{title}</dt>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <dd key={index} className="mt-1 text-sm text-gray-900">
            {item}
          </dd>
        ))
      ) : (
        <dd className="mt-1 text-sm text-gray-500">No data available</dd>
      )}
    </div>
  );
};

// Reusable Component for Displaying Key-Value Pairs
const KeyValueListDisplay: React.FC<{
  title: string;
  items: { label: string; value: string }[];
}> = ({ title, items }) => {
  return (
    <div className="sm:col-span-1">
      <dt className="text-base font-medium text-gray-500">{title}</dt>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <dd key={index} className="mt-1 text-sm text-gray-900 capitalize">
            {item.label} : {item.value}
          </dd>
        ))
      ) : (
        <dd className="mt-1 text-sm text-gray-500">No data available</dd>
      )}
    </div>
  );
};
const PreviousVisitCard: React.FC<{ previousVisit: any }> = ({
  previousVisit,
}) => {
  const navigate = useNavigate();

  const [transaction, setTransaction] = useState<any>();
  const [visitId, setVisitId] = useState(null);

  useEffect(() => {
    transactionSummary();
  }, []);

  const viewDetailedVisit = (id: number) => {
    window.open(`/visits/${id}`);
  };

  const getPrescription = (id: number) => {
    getVisitPrescription(id).then((res: any) => {
      window.open(` ${BASE_URL}` + res.data.link);
    });
  };

  const viewAttachments = (url: string) => {
    getVisitFile({ file: url }).then((res: any) => {
      window.open(res.data);
    });
  };

  const transactionSummary = () => {
    getPaymentSummary(previousVisit.id).then((res: any) => {
      setTransaction(res.data.data);
    });
  };

  const redirectToTransactions = (id: any) => {
    navigate(`/transactions/visits/${id}`);
  };

  return (
    <div className="pt-2 lg:pt-5">
      <div className="absolute w-3 h-3 bg-primary rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
      <time className="mb-1 text-sm font-normal leading-none text-active dark:text-gray-500">
        {previousVisit.date}
      </time>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
          <dl className="grid grid-cols-1 lg:gap-x-4 gap-x-2 gap-y-3 lg:gap-y-8 lg:grid-cols-4">
            <div>
              <dt className="text-base font-medium text-gray-500">
                Chief Complaint
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {previousVisit.chiefComplaint ?? "--"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-base font-medium text-gray-500">Symptoms</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {(previousVisit.symptoms &&
                  previousVisit.symptoms.length > 0 &&
                  previousVisit.symptoms.map((item: any, index: number) => (
                    <span
                      key={index}
                      className="bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800 rounded-full mr-2"
                    >
                      {item}
                    </span>
                  ))) ||
                  "No data available"}
              </dd>
            </div>

            <ListDisplay
              title="Provisional Diagnosis"
              items={previousVisit.provisionalDiagnosis || []}
            />

            <ListDisplay
              title="Final Diagnosis"
              items={previousVisit.finalDiagnosis || []}
            />

            {/* Services Provided */}
            <div className="sm:col-span-1">
              <dt className="text-base font-medium text-gray-500">
                Services Provided
              </dt>
              {previousVisit.services && previousVisit.services.length > 0 ? (
                previousVisit.services.map((service: any, index: number) => (
                  <span
                    key={index}
                    className="bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800 rounded-full mr-2"
                  >
                    {service.name}{" "}
                    {service.quantity > 1 && <span>x {service.quantity}</span>}
                  </span>
                ))
              ) : (
                <dd className="mt-1 text-sm text-gray-500">
                  No services provided
                </dd>
              )}
            </div>

            {previousVisit &&
              previousVisit.meta &&
              previousVisit.meta.vitals && (
                <KeyValueListDisplay
                  title="Vitals"
                  items={previousVisit.meta.vitals || []}
                />
              )}

            {transaction && (
              <>
                {/* Total Amount */}
                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500">
                    Total Amount
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    ₹{transaction.total ?? "0"}
                  </dd>
                </div>


                {/* Total Paid Amount */}
                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500">
                    Total Paid Amount
                  </dt>
                  <dd className="mt-1 text-sm text-primary cursor-pointer">
                    <button
                      onClick={() =>
                        redirectToTransactions(previousVisit.id)
                      }
                      className="underline text-primary hover:text-hover"
                    >
                      ₹{transaction.amountPaid ?? "0"}
                    </button>
                  </dd>
                </div>

                 {/* Pending Amount */}
                 <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500">
                    Pending Amount
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    ₹{transaction.amountDue ?? "0"}
                  </dd>
                </div>
              </>
            )}

            {/* Medicines Section */}
            <div className="sm:col-span-4">
              <dt className="text-base font-medium text-gray-500">
                Prescribed Medicines
                {previousVisit.medicines && previousVisit.medicines.length ? (
                  // <button
                  //   onClick={() => getPrescription(previousVisit.id)}
                  //   type="button"
                  //   className="justify-center float-right text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  // >
                  //   View
                  // </button>
                  <></>
                ) : (
                  <div className="pt-2 text-sm font-medium text-gray-600">
                    No medicines Added
                  </div>
                )}
              </dt>

              {previousVisit.medicines &&
                previousVisit.medicines.length > 0 && (
                  <dd className="mt-1 text-sm text-gray-900">
                    <table className="min-w-full border divide-y divide-gray-300 rounded-md">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="py-2 pl-4 pr-3 text-sm font-semibold text-left text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th className="px-3 py-2 text-sm font-semibold text-left text-gray-900">
                            Dosage
                          </th>
                          <th className="px-3 py-2 text-sm font-semibold text-left text-gray-900 ">
                            Qty.
                          </th>
                          <th className="px-3 py-2 text-sm font-semibold text-left text-gray-900 ">
                            Note
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {previousVisit.medicines.map(
                          (medicine: any, index: number) => (
                            <tr key={index}>
                              <td className="py-2 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                                {medicine.name}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                {medicine.morning || "0"}--
                                {medicine.afternoon || "0"}--
                                {medicine.night || "0"} (
                                {medicine.meal === "before_meal"
                                  ? "Before Meal"
                                  : "After Meal"}
                                )
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                {medicine.duration} {medicine.durationPeriod}
                              </td>
                              <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                {medicine.note}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </dd>
                )}
            </div>

            {/* Attachments Section */}
            <div className="sm:col-span-3">
              <dt className="text-base font-medium text-gray-500">
                Attachments
              </dt>
              {previousVisit.attachments &&
              previousVisit.attachments.length > 0 ? (
                <ul>
                  {previousVisit.attachments.map(
                    (attachment: any, index: number) => (
                      <li key={index} className="mt-2">
                        <button
                          type="button"
                          onClick={() => viewAttachments(attachment.file)}
                          className="underline text-primary hover:text-hover"
                        >
                          {attachment.name}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <dd className="mt-1 text-sm text-gray-500">
                  No attachments available
                </dd>
              )}
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default PreviousVisitCard;
