import {
  add,
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  parse,
  startOfMonth,
  startOfToday,
  startOfWeek,
  sub,
  subWeeks,
} from "date-fns";
import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AddEvent from "./AddEvent";
import { Event } from "../../interfaces/Event";
import { getEvents } from "../../apis/event";
import EventDetails from "./EventDetails";
import DaysEvents from "./DaysEvents";
import useWindowSize from "../../components/ScreenSizeCalculator";
import MonthView from "./MonthView";
import WeekView from "./WeekView";
import VisitContextMenu from "../visits/VisitContextMenu";
import { changeVisitStatusById } from "../../apis/visit";
import { ListVisitItem } from "../../interfaces/Visit";
import AddAppointment from "../visits/AddAppointment";
import AddFollowUpVisit from "../visits/AddFollowUpVisit";

const Calendar: React.FC = () => {
  const { width } = useWindowSize();
  const today = startOfToday();
  const [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  const [title, setTitle] = useState(format(today, "MMM-yyyy"));

  const [firstDayCurrentMonth, setFirstDayCurrentMonth] = useState(
    parse(currentMonth, "MMM-yyyy", new Date())
  );
  const [calendarDays, setCaledarDays] = useState<any>();
  const [weekDays, setWeekDays] = useState<any>();

  const [showEventModal, setShowEventModal] = useState(false);
  const [showEventDetails, setShowEventDetailsModal] = useState(false);
  const [showViewList, setShowViewList] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [showMenus, setShowMenus] = useState(false);
  const [events, setEvents] = useState<Array<Event>>([]);
  const [isDaysEventsVisible, setDaysEventsVisible] = useState<boolean>(false);
  const [daysEvents, setDaysEvents] = useState<Array<Event>>([]);
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
  const [currentVisit, setCurrentVisit] = useState<ListVisitItem>();
  const calendarViews = ["month", "week"];
  const [startDateOfWeek, setStartDateOfWeek] = useState<any>(new Date());
  const [selectedCalendarView, setSelectedCalendarView] =
    useState<string>("month");
  const [isRescheduleVisitModalOpen, setRescheduleVisitModalVisibility] =
    useState<boolean>(false);
  const [patientId, setPatientId] = useState<Number>();
  const [parentId, setParentId] = useState<Number>();

  const [selectedVisit, setSelectedVisit] = useState<ListVisitItem>();
  const [rescheduleVisitUserId, setRescheduleVisitUserId] = useState<
    number | any
  >();
  const [followUpVisitUserId, setFollowUpVisitUserId] = useState<
    number | null
  >();
  const [isAddFollowUpVisitModalOpen, setAddFollowUpModalVisibility] =
    useState<boolean>(false);
  const [clinicVisitTypeLayoutId, setClinicVisitTypeLayoutId] =
    useState<Number>();
  const [chiefComplaint, setChiefComplaint] = useState<string>("");
  const [clinicVisitTypeId, setClinicVisitTypeId] = useState<Number>();

  useEffect(() => {
    const calendarView = window.localStorage.getItem("calendarView");
    if (calendarView) {
      setSelectedCalendarView(calendarView);
    }
    fetchEvents(firstDayCurrentMonth);
  }, []);

  useEffect(() => {
    const showCalendar = async () => {
      if (width < 1024 || selectedCalendarView === "week") {
        await fetchEvents(new Date(firstDayCurrentMonth));
        calculateWeekDays({ date: new Date(firstDayCurrentMonth), events });
        setSelectedCalendarView("week");
        window.localStorage.setItem("calendarView", "week");
      } else {
        await fetchEvents(new Date(firstDayCurrentMonth));
        calculateCalendarDays({ date: new Date(firstDayCurrentMonth), events });
        setSelectedCalendarView("month");
        window.localStorage.setItem("calendarView", "month");
      }
    };

    showCalendar();
  }, [width, selectedCalendarView]);

  const closeModal = () => {
    setShowEventModal(false);
    fetchEvents(firstDayCurrentMonth);
  };

  const fetchEvents = (date: any) => {
    const startDate =
      selectedCalendarView === "month"
        ? format(startOfWeek(startOfMonth(date)), "yyyy-MM-dd")
        : format(startOfWeek(date), "yyyy-MM-dd");
    const endDate =
      selectedCalendarView === "month"
        ? format(endOfWeek(endOfMonth(date)), "yyyy-MM-dd")
        : format(endOfWeek(date), "yyyy-MM-dd");

    getEvents({
      start: startDate,
      end: endDate,
      per_page: 1000,
    }).then((res: any) => {
      setEvents(res.data);

      if (selectedCalendarView === "month") {
        calculateCalendarDays({ date, events: res.data });
      } else {
        calculateWeekDays({ date, events: res.data });
      }
    });
  };

  const setFirstDayOfCurrentMonth = () => {
    setFirstDayCurrentMonth(parse(currentMonth, "MMM-yyyy", new Date()));
  };

  const goToToday = () => {
    setCurrentMonth(format(new Date(), "MMM-yyyy"));
    setTitle(format(new Date(), "MMM-yyyy"));

    setFirstDayCurrentMonth(
      parse(format(new Date(), "MMM-yyyy"), "MMM-yyyy", new Date())
    );
    fetchEvents(firstDayCurrentMonth);
  };

  const handleNext = () => {
    if (selectedCalendarView === "month") {
      nextMonth();
    } else {
      nextWeek();
    }
  };

  const nextMonth = () => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    fetchEvents(firstDayNextMonth);
    setFirstDayCurrentMonth(
      parse(format(firstDayNextMonth, "MMM-yyyy"), "MMM-yyyy", new Date())
    );
    setCurrentMonth(() => format(firstDayNextMonth, "MMM-yyyy"));
    setTitle(() => format(firstDayNextMonth, "MMM-yyyy"));
  };

  const nextWeek = () => {
    const nextWeekStart = addWeeks(startDateOfWeek, 1);
    fetchEvents(nextWeekStart);
    const newMonth = format(nextWeekStart, "MMM-yyyy");
    const weekEnd = endOfWeek(nextWeekStart, { weekStartsOn: 0 }); // Week ends on Saturday
    calculateTitle(nextWeekStart, weekEnd);

    if (newMonth !== currentMonth) {
      setCurrentMonth(newMonth);
      setFirstDayCurrentMonth(parse(newMonth, "MMM-yyyy", new Date()));
    }
  };

  const handlePrevious = () => {
    if (selectedCalendarView === "month") {
      previousMonth();
    } else {
      previousWeek();
    }
  };

  const previousMonth = () => {
    // setFirstDayOfCurrentMonth();
    const firstDayPreviousMonth = sub(firstDayCurrentMonth, { months: 1 });
    fetchEvents(firstDayPreviousMonth);
    setFirstDayCurrentMonth(
      parse(format(firstDayPreviousMonth, "MMM-yyyy"), "MMM-yyyy", new Date())
    );
    setCurrentMonth(() => format(firstDayPreviousMonth, "MMM-yyyy"));
    setTitle(() => format(firstDayPreviousMonth, "MMM-yyyy"));
  };

  const previousWeek = () => {
    const previousWeekStart = subWeeks(startDateOfWeek, 1);
    const weekEnd = endOfWeek(previousWeekStart, { weekStartsOn: 0 }); // Week ends on Saturday
    calculateTitle(previousWeekStart, weekEnd);

    fetchEvents(previousWeekStart);
    const newMonth = format(previousWeekStart, "MMM-yyyy");
    if (newMonth !== currentMonth) {
      setCurrentMonth(newMonth);

      setFirstDayCurrentMonth(parse(newMonth, "MMM-yyyy", new Date()));
    }
  };

  //for month view calculation
  const calculateCalendarDays = ({ date, events }: any) => {
    const monthDays = eachDayOfInterval({
      start: startOfWeek(startOfMonth(date)),
      end: endOfWeek(endOfMonth(date)),
    });
    const calendarDays = monthDays.map((i: any) => {
      const eventsForDay =
        events && events.length > 0
          ? events.filter((event: Event) =>
              isSameDay(new Date(i), new Date(event.startDate))
            )
          : [];

      return {
        date: i,
        events: eventsForDay,
      };
    });
    setCaledarDays(calendarDays);
  };

  //for week view calculation
  const calculateWeekDays = ({ date, events }: any) => {
    setStartDateOfWeek(startOfWeek(date));

    const weekDays = eachDayOfInterval({
      start: startOfWeek(date),
      end: endOfWeek(date),
    });
    const calendarDays = weekDays.map((i: any) => {
      const eventsForDay =
        events && events.length > 0
          ? events.filter((event: Event) =>
              isSameDay(new Date(i), new Date(event.startDate))
            )
          : [];

      return {
        date: i,
        events: eventsForDay,
      };
    });
    setWeekDays(calendarDays);
  };

  const calculateTitle = (weekStart: Date, weekEnd: Date) => {
    const startMonth = format(weekStart, "MMM"); // "Oct"
    const endMonth = format(weekEnd, "MMM"); // "Nov"
    const startYear = format(weekStart, "yyyy"); // "2024"
    const endYear = format(weekEnd, "yyyy"); // "2024"

    // Generate the title
    let calculatedTitle;
    if (startYear === endYear) {
      if (startMonth === endMonth) {
        calculatedTitle = `${startMonth} ${startYear}`; // Same month and year
      } else {
        calculatedTitle = `${startMonth}-${endMonth} ${startYear}`; // Same year, different months
      }
    } else {
      calculatedTitle = `${startMonth} ${startYear} - ${endMonth} ${endYear}`; // Different years
    }
    setTitle(calculatedTitle);
  };

  const changeCalendarView = (view: string) => {
    setFirstDayOfCurrentMonth();
    setCurrentMonth(currentMonth);
    window.localStorage.setItem("calendarView", view);
    setSelectedCalendarView(view);
    setShowViewList(false);
  };

  const handleViewEvent = (event: Event) => {
    setCurrentEvent(event);
    setShowEventDetailsModal(true);
  };

  const handleViewVisit = (visit: ListVisitItem, event: Event) => {
    setCurrentVisit(visit);
    setCurrentEvent(event);
    setShowEventDetailsModal(true);
  };

  const handleWeekViewEvent = (event: Event) => {
    setCurrentEvent(event);
    setShowEventDetailsModal(true);
  };

  const handleWeekDayClick = (day: any) => {
    setSelectedDay(day.date);
    setDaysEvents([...day.events]);
    setDaysEventsVisible(true);
  };

  const handleEventDetailsModalClose = () => {
    setShowEventDetailsModal(false);
    setCurrentEvent(null);
  };

  const handleDayClick = (day: any) => {
    setSelectedDay(day.date);
    setDaysEvents([...day.events]);
    setDaysEventsVisible(true);
  };

  const handleDaysEventsModalClose = () => {
    setDaysEventsVisible(false);
    const events: Array<Event> = [];
    setDaysEvents([...events]);
  };

  const handleEventFormVisibilityInDaysEvent = (selectedDay: any) => {
    setDaysEventsVisible(false);
    const events: Array<Event> = [];
    setDaysEvents([...events]);
    setShowEventModal(true);
  };

  const handleRescheduleVisit = (visit: any) => {
    setPatientId(visit.patientId);
    setSelectedVisit(visit);
    if (visit.user) {
      setRescheduleVisitUserId(visit.user.id);
    }
    setRescheduleVisitModalVisibility(true);
  };

  const handleFollowUpVisit = (visit: any) => {
    setClinicVisitTypeId(visit.clinicVisitTypeId);
    if (visit?.userId) {
      setFollowUpVisitUserId(visit?.userId);
    }
    setClinicVisitTypeLayoutId(visit.clinicVisitTypeLayoutId);
    if (visit.patientId) {
      setPatientId(visit.patientId);
    }
    setParentId(visit.parentId ? visit.parentId : visit.id);
    setChiefComplaint(visit.chiefComplaint);
    setAddFollowUpModalVisibility(true);
  };

  const handleCloseModal = () => {
    setRescheduleVisitModalVisibility(false);
    setRescheduleVisitUserId(null);
    setAddFollowUpModalVisibility(false);
    setFollowUpVisitUserId(null);
    fetchEvents(firstDayCurrentMonth);
  };

  return (
    <div className="lg:flex lg:flex-col">
      <header className="relative flex items-center justify-between px-6 py-4 border-b border-gray-200 lg:flex-none">
        <h1 className="text-lg font-semibold text-gray-900">
          <span>{title}</span>
        </h1>
        <div className="flex items-center">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              onClick={() => handlePrevious()}
              type="button"
              className="flex items-center justify-center py-2 pl-3 pr-4 text-gray-400 bg-white border border-r-0 border-gray-300 rounded-l-md hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              {/* Heroicon name: solid/chevron-left */}
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={goToToday}
              type="button"
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative w-px h-5 -mx-px bg-gray-300 md:hidden"></span>
            <button
              onClick={() => handleNext()}
              type="button"
              className="flex items-center justify-center py-2 pl-4 pr-3 text-gray-400 bg-white border border-l-0 border-gray-300 rounded-r-md hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex md:items-center">
            <div className="relative">
              <button
                type="button"
                className="flex items-center py-2 pl-3 pr-2 ml-2 text-sm font-medium text-gray-700 capitalize bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                id="menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={() => setShowViewList(!showViewList)}
              >
                {selectedCalendarView}
                <svg
                  className="w-5 h-5 ml-2 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {showViewList && (
                <div
                  className="absolute right-0 z-10 w-full mt-3 overflow-hidden origin-top-right bg-white rounded-md shadow-lg focus:outline-none ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex={-1}
                >
                  <div className="px-1 py-1" role="none">
                    {calendarViews.map((item, index) => (
                      <button
                        key={index}
                        className="block w-full px-4 py-2 text-sm text-gray-700 capitalize rounded-md cursor-pointer hover:bg-gray-100"
                        role="menuitem"
                        tabIndex={-1}
                        onClick={() => changeCalendarView(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-px ml-6 bg-gray-300"></div>
            <button
              onClick={() => setShowEventModal(true)}
              type="button"
              className="px-4 py-2 ml-6 text-sm font-medium border border-transparent rounded-md shadow-sm text-light bg-primary focus:outline-none hover:bg-hover focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Add Event
            </button>
          </div>
          {/* mobile menus */}
          <div className="relative ml-6 md:hidden">
            <button
              onClick={() => setShowMenus(!showMenus)}
              type="button"
              className="flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100"
              aria-expanded={showMenus}
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
            {showMenus && (
              <div
                className="absolute right-0 z-10 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex={-1}
              >
                <div className="py-1" role="none">
                  {calendarViews.map((item, index) => (
                    <button
                      key={index}
                      className="block px-4 py-2 text-sm text-gray-700 capitalize cursor-pointer hover:bg-gray-100"
                      role="menuitem"
                      tabIndex={-1}
                      onClick={() => changeCalendarView(item)}
                    >
                      {item}
                    </button>
                  ))}
                </div>
                <div className="py-1" role="none">
                  <button
                    onClick={() => {
                      setShowEventModal(true);
                      setShowMenus(false);
                    }}
                    type="button"
                    className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    tabIndex={-1}
                  >
                    Add event
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>

      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="flex text-xs leading-6 text-gray-700 bg-white lg:flex-auto">
          <div className="flex flex-auto overflow-auto bg-white isolate justify">
            {/* small screen view */}
            {selectedCalendarView === "week" ? (
              <WeekView
                weekDays={weekDays}
                startDateOfWeek={startDateOfWeek}
                events={events}
                onDayClick={handleDayClick}
                onViewDetails={handleViewEvent}
                onViewVisit={handleViewVisit}
              />
            ) : (
              <MonthView
                firstDayCurrentMonth={firstDayCurrentMonth}
                selectedDay={selectedDay}
                calendarDays={calendarDays}
                onDayClick={handleDayClick}
                onViewDetails={handleViewEvent}
                onViewVisit={handleViewVisit}
              />
            )}
          </div>
        </div>
        {showEventModal && (
          <Modal
            title="Add Event"
            onClose={() => closeModal()}
            modalContent={
              <AddEvent
                startDate={selectedDay}
                closeModal={() => closeModal()}
              />
            }
          />
        )}
        {showEventDetails && currentVisit && currentEvent?.isAppointment && (
          <Modal
            title="Event Details"
            secondaryAction={
              <div>
                <VisitContextMenu
                  visit={currentVisit}
                  onReschedule={(visit) => {
                    handleRescheduleVisit(visit);
                  }}
                  onFollowUp={(visit) => {
                    handleFollowUpVisit(visit);
                  }}
                  onStatusChange={(visitId, status) => {
                    changeVisitStatusById({ id: visitId, visitStatus: status });
                  }}
                />
              </div>
            }
            modalContent={
              <EventDetails
                event={currentEvent}
                closeModal={() => handleEventDetailsModalClose()}
              />
            }
          />
        )}

        {showEventDetails && currentEvent && !currentEvent.isAppointment && (
          <Modal
            title="Event Details"
            modalContent={
              <EventDetails
                event={currentEvent}
                closeModal={() => handleEventDetailsModalClose()}
              />
            }
          />
        )}
        {isDaysEventsVisible && selectedDay && (
          <Modal
            title={`Events | ${format(new Date(selectedDay), "do MMM yyyy")}`}
            onClose={handleDaysEventsModalClose}
            modalContent={
              <DaysEvents
                events={daysEvents}
                date={selectedDay}
                closeModal={() => handleDaysEventsModalClose()}
                handleEventFormVisibility={() =>
                  handleEventFormVisibilityInDaysEvent(selectedDay)
                }
                handleReschedule={(visit: any) => {
                  handleRescheduleVisit(visit);
                }}
                handleFollowUp={(visit: any) => {
                  handleFollowUpVisit(visit);
                }}
              />
            }
          />
        )}

        {isRescheduleVisitModalOpen && (
          <Modal
            title="Appointment"
            onClose={() => handleCloseModal()}
            modalContent={
              <AddAppointment
                patientId={patientId}
                userId={rescheduleVisitUserId}
                existingVisitData={selectedVisit}
                closeModal={() => handleCloseModal()}
              />
            }
          />
        )}

        {isAddFollowUpVisitModalOpen && followUpVisitUserId && (
          <Modal
            title="Follow Up Visit"
            onClose={() => handleCloseModal()}
            modalContent={
              <AddFollowUpVisit
                patientId={patientId}
                parentId={parentId}
                userId={followUpVisitUserId}
                chiefComplaint={chiefComplaint}
                clinicVisitTypeId={clinicVisitTypeId}
                clinicVisitTypeLayoutId={clinicVisitTypeLayoutId}
                closeModal={() => handleCloseModal()}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default Calendar;
