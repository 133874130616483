import * as React from "react";

const Modal: React.FC<{
  modalContent: React.ReactNode;
  title?: string;
  width?: string;
  secondaryAction?: React.ReactNode;
  onClose?: () => void;
}> = ({
  modalContent,
  title,
  width = "sm:max-w-lg",
  onClose,
  secondaryAction,
}) => {
  return (
    <div
      className="fixed inset-0 z-50 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="z-50 flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        {title ? (
          <div
            className={`inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:align-middle sm:w-full ${width}`}
          >
            <div className="flex justify-between p-4 text-white bg-primary">
              <span className="font-bold">{title}</span>
              <div className="flex">
                {secondaryAction && <span>{secondaryAction}</span>}
                {onClose && (
                  <span className="cursor-pointer" onClick={onClose}>
                    X
                  </span>
                )}
                
              </div>
            </div>

            <div className="p-4">{modalContent}</div>
          </div>
        ) : (
          <div
            className={`inline-block p-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:align-middle sm:w-full ${width}`}
          >
            {modalContent}
          </div>
        )}
      </div>
    </div>
  );
};
export default Modal;
