import { Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getPermissions,
  getRoleAndPermissionById,
  getRoles,
  saveRolesAndPermissions,
  updateRolesAndPermissions,
} from "../../apis/rolesAndPermissions";
import { Permissions, Roles } from "../../interfaces/RolesAndPermissions";
import { useNotification } from "../../NotificationContext";
import { LanguageContext } from "../../providers/LanguageProvider";
const AddPermissions: React.FC<{
  closeModal: () => void;
  editableId: any;
}> = ({ closeModal, editableId }) => {
  const defaultRolesAndPermissions = {
    roles: [],
    permissions: [],
  };

  const [rolesAndPermissions, setRolesAndPermissions] = useState(
    defaultRolesAndPermissions
  );
  const [roles, setRoles] = useState<Array<Roles>>([]);
  const [permissions, setPermissions] = useState<Array<Permissions>>([]);
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();
  const Option = Select;
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    if (editableId) {
      fetchRoleAndPermission(editableId);
    }
  }, [editableId]);

  useEffect(() => {
    fetchRoles();
    fetchPermissions();
   
  }, []);

  const fetchRoles = () => {
    getRoles().then((res: any) => {
      setRoles(res.data);
    });
  };

  const fetchPermissions = () => {
    getPermissions().then((res: any) => {
      setPermissions(res.data);
    });
  };

  const fetchRoleAndPermission = (id: number) => {
    getRoleAndPermissionById(id).then((res: any) => {
      setRolesAndPermissions(res.data);
    });
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (rolesAndPermissions?.roles === null) {
      validationErrors.roles = "* role is required";
    }
    if (rolesAndPermissions?.permissions === null) {
      validationErrors.permissions = "* Permission is required";
    }
    const hasErrors = Boolean(Object.keys(validationErrors).length);
    return { hasErrors, validationErrors };
  };

  const addRolesAndPermissions = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveRolesAndPermissions({
      ...rolesAndPermissions,
    }).then((res: any) => {
      showNotification(
        "success",
        "Roles And Permissions Added successfully",
        "",
        "topRight"
      );
      getRoles();
      getPermissions();
      closeModal();
    });
  };

  const updateRolesAndPermisionsData = () => {
    updateRolesAndPermissions({
      ...rolesAndPermissions,
      id: editableId,
    }).then((res) => {
      showNotification(
        "success",
        "Roles And Permissions Updated successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateRolesAndPermisionsData();
    } else {
      addRolesAndPermissions();
    }
  };

  const handleRoleChange = (roles: any) => {
    setRolesAndPermissions({
      ...rolesAndPermissions,
      roles,
    });
  };

  const handlePermissionChange = (permissions: any) => {
    setRolesAndPermissions({
      ...rolesAndPermissions,
      permissions,
    });
  };

  return (
    <div className="">
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("role")}
        </label>
        <div className="mt-1">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            onChange={handleRoleChange}
            value={rolesAndPermissions.roles}
          >
            {roles.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </div>

        {errors && errors.role && (
          <span className="text-sm text-red-500 leading-0">{errors.role}</span>
        )}
      </div>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("permissions")}
        </label>
        <div className="mt-1">
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            onChange={handlePermissionChange}
            value={rolesAndPermissions.permissions}
          >
            {permissions.map((permission) => (
              <Option key={permission.id} value={permission.id}>
                {permission.name}
              </Option>
            ))}
          </Select>
        </div>

        {errors && errors.permission && (
          <span className="text-sm text-red-500 leading-0">
            {errors.permission}
          </span>
        )}
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {editableId ? t("update") : t("save")}
        </button>
        <button
          onClick={() => closeModal()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};

export default AddPermissions;
