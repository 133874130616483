import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../providers/LanguageProvider";
import { updateClinicDetails } from "../../apis/setting";
import { useNotification } from "../../NotificationContext";
import { Tooltip } from "antd";
import copy from "copy-to-clipboard";

const Accounts: React.FC<{
  handleUpgrade: () => void;
  handleBuy: () => void;
}> = ({ handleUpgrade, handleBuy }) => {
  useEffect(() => {
    fetchActiveClinic();
  }, []);

  const { translate: t } = useContext(LanguageContext);
  const [clinic, setClinic] = useState<Array<any>>([]);
  const [editableField, setEditableField] = useState<string>();
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>(null);
  const [copied, setCopied] = useState(false);

  const fetchActiveClinic = () => {
    const userObj = window.localStorage.getItem("user");
    if (userObj) {
      const user = JSON.parse(userObj);
      const activeClinic = user.activeClinic;
      const clinicObj = [
        {
          label: "name",
          value: activeClinic?.name,
          canEdit: true,
          action: "edit",
        },
        {
          label: "address",
          value: activeClinic?.address,
          canEdit: true,
          action: "edit",
        },
        {
          label: "phone",
          value: activeClinic?.phone,
          canEdit: true,
          action: "edit",
        },

        {
          label: "whatsappBalance",
          value: activeClinic?.whatsappBalance ?? "Unlimited",
          canEdit: true,
          action: "buy",
        },
        {
          label: "textBalance",
          value: activeClinic?.textMessageBalance ?? "Unlimited",
          canEdit: true,
          action: "buy",
        },
        {
          label: "plan",
          value: activeClinic?.plan,
          canEdit: true,
          action: "upgrade",
        },
        {
          label: "numberOfDoctors",
          value: activeClinic?.numberOfDoctors,
          canEdit: false,
        },
        {
          label: "patientLimit",
          value: activeClinic?.patientLimit ?? "Unlimited",
          canEdit: false,
        },
        {
          label: "numberOfVisits",
          value: activeClinic?.numberOfVisits ?? "Unlimited",
          canEdit: false,
        },
        {
          label: "referralCode",
          value: activeClinic?.referralCode,
          canEdit: true,
          action: "copy",
        },
      ];
      setClinic(clinicObj);
    }
  };

  const updateSettingsData = (key: string, value: string) => {
    if (value.length > 0) {
      updateClinicDetails({ [key]: value })
        .then(() => {
          setEditableField("");
          showNotification(
            "success",
            "Record Updated Successfully!",
            "",
            "topRight"
          );
        })
        .catch(() => {
          showNotification("error", "Something went wrong!", "", "topRight");
        });
    } else {
      setErrors({ [key]: `${key} is required` });
    }
  };

  const handleChangeClinicProperty = (item: any, value: string) => {
    const updatedData = clinic.map((i) => {
      if (i.label === item.label) {
        return {
          ...i,
          value,
        };
      } else {
        return i;
      }
    });
    setClinic(updatedData);
  };

  const renderItem = (item: any) => {
    if (item.value !== null) {
      if (editableField === item.label) {
        return (
          <>
            <input
              name="code"
              type="text"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder=""
              value={item.value}
              onChange={(e) => {
                handleChangeClinicProperty(item, e.target.value);
              }}
              required
            />
            {errors && Object.hasOwn(errors, item.label) && (
              <span className="text-sm text-red-500">{errors[item.label]}</span>
            )}
          </>
        );
      } else if (item.label === "plan") {
        return (
          <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-indigo-700 rounded-md bg-indigo-50 ring-1 ring-inset ring-indigo-700/10">
            {item.value}
          </span>
        );
      } else if (item.label === "referralCode") {
        return (
          <span className="flex items-center space-x-2">
            <span className="">{item.value}</span>
            {copied && (
              <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-700 bg-green-100 rounded-md ring-1 ring-inset ring-green-700/10">
                Copied
              </span>
            )}
          </span>
        );
      } else {
        return <span className="capitalize ">{item.value}</span>;
      }
    }
    return "";
  };

  const handleOnActionClick = (item: any) => {
    if (item.action === "edit") {
      setEditableField(item.label);
    } else if (item.action === "buy") {
      handleBuy();
    } else if (item.action === "upgrade") {
      handleUpgrade();
    } else if (item.action === "copy") {
      handleCopy(item.value);
    }
  };

  const handleCopy = (referralCode: any) => {
    copy(referralCode);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <div className="py-6">
      <div>
        <div className="mt-6 divide-y divide-gray-200">
          <div className="flex justify-between ">
            <div className="space-y-1">
              <h3 className="text-sm font-medium leading-6 text-gray-900">
                <b> {t("account_details")}</b>
              </h3>

              <p className="max-w-2xl text-sm text-gray-500">
                Account related information
              </p>
            </div>
          </div>
          <div className="px-4 mt-2 bg-white divide-y divide-gray-200">
            <dl className="divide-y divide-gray-200">
              {clinic.map((item, key) => (
                <div key={key} className="grid grid-cols-3 py-4 sm:gap-4">
                  <dt className="flex items-center justify-between text-sm font-medium text-gray-500 capitalize">
                    {item.label.replace(/([A-Z])/g, " $1").toLowerCase()}
                  </dt>
                  <dd className="flex items-center justify-between col-span-2 mt-1 text-sm text-gray-900 sm:mt-0">
                    <span className="capitalize">{renderItem(item)}</span>
                    <span className="flex-shrink-0 ml-4">
                      {item.label === editableField ? (
                        <button
                          type="button"
                          className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() =>
                            updateSettingsData(item.label, item.value)
                          }
                        >
                          {t("update")}
                        </button>
                      ) : (
                        item.canEdit && (
                          <button
                            type="button"
                            onClick={() => handleOnActionClick(item)}
                            className="block px-3 py-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {t(item.action)}
                          </button>
                        )
                      )}
                    </span>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
