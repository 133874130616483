import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../../components/Modal";
import { Pagination, PaginationProps } from "antd";
import Filters from "../../components/Filters";
import { Filter } from "../../interfaces/Filter";
import {
  changeVisitStatusById,
  exportVisits,
  getVisits,
} from "../../apis/visit";
import { ListVisitItem } from "../../interfaces/Visit";
import AddAppointment from "./AddAppointment";
import AddFollowUpVisit from "./AddFollowUpVisit";
import { LanguageContext } from "../../providers/LanguageProvider";
import { format } from "date-fns";
import { changeStep, changeVisit } from "../../features/visit/visitSlice";
import { useAppDispatch } from "../../hooks";
import Prescription from "./Prescription";
import VisitContextMenu from "./VisitContextMenu";

const visitFilters: Array<Filter> = [
  {
    label: "Patient Name",
    key: "patient_name",
    value: "",
    component: "input",
  },
  {
    label: "Doctor Name",
    key: "doctor_name",
    value: "",
    component: "input",
  },
  {
    label: "Visit Type",
    key: "visit_type",
    value: "",
    component: "input",
  },
  {
    label: "Status",
    key: "status",
    value: "",
    component: "input",
  },
  {
    label: "Date",
    key: "date",
    value: "",
    component: "date",
  },
];

const VisitList: React.FC = () => {
  const permissions = JSON.parse(
    window.localStorage.getItem("permissions") ?? ""
  );
  const user = window.localStorage.getItem("user");
  const [patientId, setPatientId] = useState<Number>();
  const [parentId, setParentId] = useState<Number>();
  const [clinicVisitTypeId, setClinicVisitTypeId] = useState<Number>();
  const [clinicVisitTypeLayoutId, setClinicVisitTypeLayoutId] =
    useState<Number>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const currentDate = new Date();
  const [meta, setMeta] = useState<any>();
  const [isAppointmentModalOpen, setAppointmentModalVisibility] =
    useState<boolean>(false);
  const [isAddFollowUpVisitModalOpen, setAddFollowUpModalVisibility] =
    useState<boolean>(false);
  const [isMobileActionsVisible, setMobileActionsVisible] =
    useState<boolean>(false);

  const [isPrescriptionVisible, setIsPrescriptionVisible] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState<ListVisitItem>();

  const [visits, setVisits] = useState<Array<ListVisitItem>>([]);

  const [from] = useState(currentDate.toISOString().slice(0, 10));
  const [to] = useState(currentDate.toISOString().slice(0, 10));
  const { translate: t } = useContext(LanguageContext);
  const [filters] = useState<Array<Filter>>(visitFilters);
  const [isClearFilterList] = useState(false);
  const [, setSearchParams] = useSearchParams();
  const [followUpVisitUserId, setFollowUpVisitUserId] = useState<
    number | null
  >();
  const [rescheduleVisitUserId, setRescheduleVisitUserId] = useState<
    number | any
  >();
  const [isRescheduleVisitModalOpen, setRescheduleVisitModalVisibility] =
    useState<boolean>(false);

  const [chiefComplaint, setChiefComplaint] = useState<string>("");

  useEffect(() => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      fetchVisits({ per_page: 10, ...filters });
    } else {
      fetchVisits();
    }
  }, [search]);
  const dispatch = useAppDispatch();

  const handleFilters = (params: any) => {
    if (params.length > 0) {
      let updatedQueryParams = new URLSearchParams();
      params.forEach((i: any) => {
        updatedQueryParams.set(i.key, i.value);
      });
      setSearchParams(updatedQueryParams.toString());
    } else {
      navigate(`/visits`);
    }
  };

  const handleCloseModal = () => {
    setAppointmentModalVisibility(false);
    setAddFollowUpModalVisibility(false);
    setFollowUpVisitUserId(null);
    setRescheduleVisitModalVisibility(false);
    setRescheduleVisitUserId(null);
    fetchVisits();
  };

  const fetchVisits = (params = {}) => {
    getVisits(params).then((res: any) => {
      setVisits(res.data);
      setMeta(res.meta);
    });
  };

  //use per_page
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    fetchVisits({ page, per_page: pageSize });
  };

  const getTodaysVisits = () => {
    if (user) {
      const loggedInUser = JSON.parse(user);
      if (!loggedInUser.isAdmin) {
        const canViewAllVisitsPermission = permissions.find(
          (el: string) => el === "can_view_all_visits"
        );
        if (canViewAllVisitsPermission) {
          fetchVisits({ date: from + "to" + to, per_page: 10 });
        } else {
          const canSeeLoggedInUserVisitsPermission = permissions.find(
            (el: string) => el === "can_see_only_logged_in_users_visits"
          );
          if (canSeeLoggedInUserVisitsPermission) {
            fetchVisits({
              date: from + "to" + to,
              per_page: 10,
              user_id: loggedInUser.id,
            });
          }
        }
      } else {
        fetchVisits({ date: from + "to" + to, per_page: 10 });
      }
    }
  };

  const handleStatusChange = (visitId: any, status: any) => {
    changeVisitStatusById({ id: visitId, visitStatus: status }).then(
      (res: any) => {
        fetchVisits();
      }
    );
  };

  const handleFollowUpVisit = (visit: ListVisitItem) => {
    setClinicVisitTypeId(visit.clinicVisitTypeId);

    if (visit?.userId) {
      setFollowUpVisitUserId(visit?.userId);
    }

    setClinicVisitTypeLayoutId(visit.clinicVisitTypeLayoutId);
    if (visit.patientId) {
      setPatientId(visit.patientId);
    }
    setParentId(visit.parentId ? visit.parentId : visit.id);
    setChiefComplaint(visit.chiefComplaint);
    setAddFollowUpModalVisibility(true);
  };

  const handleRescheduleVisit = (visit: any) => {
    setPatientId(visit.patientId);
    setSelectedVisit(visit);
    if (visit.user) {
      setRescheduleVisitUserId(visit.user.id);
    }
    setRescheduleVisitModalVisibility(true);
  };

  const downloadVisitsList = () => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      exportVisitsList({ ...filters });
    } else {
      exportVisitsList({});
    }
  };

  const exportVisitsList = (params: any) => {
    exportVisits(params).then((res: any) => {
      downloadFile(res.data);
    });
  };

  const downloadFile = (data: any) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    const today = format(new Date(), "dd_MM_yyyy_hh_mm");
    downloadLink.download = "visits-" + today + ".xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleActions = () => {
    setMobileActionsVisible(!isMobileActionsVisible);
  };

  const handleEdit = (visit: ListVisitItem) => {
    const editableVisit = { ...visit, symptoms: [], visitFiles: [] };
    dispatch(changeStep(1));
    dispatch(changeVisit(editableVisit));
    navigate(`/visits/${visit.id}`);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex lg:hidden">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("visit")}
          </h1>
        </div>

        <div className="flex flex-row-reverse w-full">
          <div className="ml-2">
            <label id="listbox-label" className="sr-only">
              Actions
            </label>
            <div className="relative">
              <div className="inline-flex divide-x rounded-md shadow-sm divide-hover">
                <button
                  type="button"
                  className="inline-flex items-center p-2 rounded-md bg-primary hover:bg-hover focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-50"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                  onClick={() => handleActions()}
                >
                  <span className="sr-only">Actions</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>
                </button>
              </div>

              {isMobileActionsVisible && (
                <ul
                  className="absolute right-0 z-10 mt-2 overflow-hidden origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg w-44 ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="listbox"
                  aria-labelledby="listbox-label"
                >
                  <li
                    className="px-4 py-2 text-sm text-gray-900 cursor-default"
                    id="listbox-option-0"
                    onClick={() => {
                      setAppointmentModalVisibility(true);
                      setMobileActionsVisible(false);
                    }}
                  >
                    {t("schedule_an_appointment")}
                  </li>
                  <li
                    className="px-4 py-2 text-sm text-gray-900 cursor-default"
                    id="listbox-option-1"
                    onClick={() => {
                      fetchVisits();
                      setMobileActionsVisible(false);
                    }}
                  >
                    {t("all_visits")}
                  </li>
                  <li
                    className="px-4 py-2 text-sm text-gray-900 cursor-default"
                    id="listbox-option-2"
                    onClick={() => {
                      getTodaysVisits();
                      setMobileActionsVisible(false);
                    }}
                  >
                    {t("todays_visits")}
                  </li>
                </ul>
              )}
            </div>
          </div>
          <Filters
            clearFilterList={isClearFilterList}
            filters={filters}
            fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
          ></Filters>
        </div>
      </div>
      <div className="hidden lg:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("visit")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{t("visit_description")}</p>
        </div>
        <div className="justify-end mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <div className="mr-2">
            <Filters
              clearFilterList={isClearFilterList}
              filters={filters}
              fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
            ></Filters>
          </div>
          <button
            type="button"
            onClick={() => setAppointmentModalVisibility(true)}
            className="block px-3 py-2 mr-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("schedule_an_appointment")}
          </button>
          <button
            type="button"
            onClick={() => fetchVisits()}
            className="block px-3 py-2 mr-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("all_visits")}
          </button>
          <button
            type="button"
            onClick={() => getTodaysVisits()}
            className="block px-3 py-2 mr-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("todays_visits")}
          </button>
          <button
            type="button"
            onClick={() => navigate("/visit-register")}
            className="block px-3 py-2 mr-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("visit_register")}
          </button>

          <button
            type="button"
            onClick={() => downloadVisitsList()}
            className="block px-3 py-2 ml-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("export")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("patient_name")}
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("doctor_name")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("visit_type")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("date")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("status")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize"
                    >
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {visits && visits.length > 0 ? (
                    visits
                    .sort(
                      (a, b) =>
                        new Date(b.date).getTime() -
                        new Date(a.date).getTime()
                    )
                    .map((visit) => (
                      <tr key={visit.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                          {visit.patient?.firstName} {visit.patient?.lastName}{" "}
                        </td>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                          {visit.user?.firstName} {visit.user?.lastName}
                        </td>
                        <td className="flex gap-1 px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                          {visit.clinicVisitType?.visitType.name}
                          {visit.parentId && (
                            <span className="bg-indigo-100 text-indigo-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-indigo-900 capitalize">
                              Follow Up
                            </span>
                          )}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {format(new Date(visit.date), "dd MMM yyyy, hh:mm a")}
                        </td>

                        <td
                          className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0 capitalize`}
                        >
                          {visit.visitStatus === "scheduled" ? (
                            <span className="bg-pink-100 text-pink-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900 capitalize">
                              {t("scheduled")}
                            </span>
                          ) : visit.visitStatus === "checked_in" ? (
                            <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-900 capitalize">
                              {t("checked_in")}
                            </span>
                          ) : visit.visitStatus === "completed" ? (
                            <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900 capitalize">
                              {t("completed")}
                            </span>
                          ) : visit.visitStatus === "cancelled" ? (
                            <span className="bg-red-100 text-red-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900 capitalize">
                              {t("cancelled")}
                            </span>
                          ) : visit.visitStatus === "expired" ? (
                            <span className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-200 dark:text-gray-900 capitalize">
                              {t("expire")}
                            </span>
                          ) : null}
                        </td>

                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left capitalize whitespace-nowrap sm:pr-6">
                          <VisitContextMenu
                            visit={visit}
                            onReschedule={handleRescheduleVisit}
                            onFollowUp={handleFollowUpVisit}
                            onStatusChange={handleStatusChange}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="px-4 py-2 bg-white">
                      <td
                        colSpan={6}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-center text-gray-900 sm:pl-6"
                      >
                        {t("Record Not Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isAppointmentModalOpen && (
        <Modal
          title="Appointment"
          onClose={() => handleCloseModal()}
          modalContent={
            <AddAppointment
              patientId={null}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
      {isAddFollowUpVisitModalOpen && followUpVisitUserId && (
        <Modal
          title="Follow Up Visit"
          onClose={() => handleCloseModal()}
          modalContent={
            <AddFollowUpVisit
              patientId={patientId}
              parentId={parentId}
              userId={followUpVisitUserId}
              chiefComplaint={chiefComplaint}
              clinicVisitTypeId={clinicVisitTypeId}
              clinicVisitTypeLayoutId={clinicVisitTypeLayoutId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
      {isRescheduleVisitModalOpen && (
        <Modal
          title="Appointment"
          onClose={() => handleCloseModal()}
          modalContent={
            <AddAppointment
              patientId={patientId}
              userId={rescheduleVisitUserId}
              existingVisitData={selectedVisit}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}

      {isPrescriptionVisible && selectedVisit && (
        <Modal
          width="max-w-2xl"
          title="Prescription Preview"
          onClose={() => setIsPrescriptionVisible(false)}
          modalContent={
            <Prescription
              preview
              prescriptionDetails={{
                symptoms: selectedVisit.symptoms,
                medicines: selectedVisit.medicines,
                finalDiagnosis: selectedVisit.finalDiagnosis,
                provisionalDiagnosis: null,
                advice: selectedVisit.advice,
                patient: selectedVisit.patient,
                visitId: selectedVisit.id,
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default VisitList;
