import React, { useContext, useEffect, useState } from "react";
import { Pagination, PaginationProps } from "antd";
import Filters from "../../components/Filters";
import { Filter } from "../../interfaces/Filter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ExpenseCategory } from "../../interfaces/ExpenseCategory";
import {
  exportExpenseCategories,
  getExpenseCategories,
} from "../../apis/expenseCategory";
import Modal from "../../components/Modal";
import AddExpenseCategory from "./AddExpenseCategory";
import { LanguageContext } from "../../providers/LanguageProvider";
import { format } from "date-fns";
const ExpenseCategoryList = () => {
  const [editableId, setEditableId] = useState(null);
  const [meta, setMeta] = useState<any>();
  const [isExpenseCategoryModalOpen, setExpenseCategoryModalVisibility] =
    useState<boolean>(false);

  const [expenseCategories, setExpenseCategories] = useState<
    Array<ExpenseCategory>
  >([]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const { translate: t } = useContext(LanguageContext);

  const companyFilters: Array<Filter> = [
    {
      label: "Name ",
      key: "name",
      value: "",
      component: "input",
    },
  ];

  const [filters] = useState<Array<Filter>>(companyFilters);
  const [isClearFilterList] = useState(false);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      fetchExpenseCategories({ per_page: 10, ...filters });
    } else {
      fetchExpenseCategories({ per_page: 10 });
    }
  }, [search]);

  const handleCloseModal = () => {
    setEditableId(null);
    setExpenseCategoryModalVisibility(false);
    fetchExpenseCategories();
  };

  const fetchExpenseCategories = (params = {}) => {
    getExpenseCategories(params).then((res: any) => {
      setExpenseCategories(res.data);
      setMeta(res.meta);
    });
  };

  const handleEditExpenseCategory = (payload: any) => {
    setEditableId(payload.id);
    setExpenseCategoryModalVisibility(true);
  };

  //use per_page
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    fetchExpenseCategories({ page, per_page: pageSize });
  };

  const handleFilters = (params: any) => {
    if (params.length > 0) {
      let updatedQueryParams = new URLSearchParams();
      params.forEach((i: any) => {
        updatedQueryParams.set(i.key, i.value);
      });
      setSearchParams(updatedQueryParams.toString());
    } else {
      navigate(`/expense-categories`);
    }
  };

  const downloadExpenseCategoriesList = () => {
    if (search) {
      const filters = JSON.parse(
        '{"' +
          decodeURI(search.substring(1))
            .replace(/\+/g, " ")
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      exportExpensecategoryList({ ...filters });
    } else {
      exportExpensecategoryList({});
    }
  };

  const exportExpensecategoryList = (params: any) => {
    exportExpenseCategories(params).then((res: any) => {
      downloadFile(res.data);
    });
  };

  const downloadFile = (data: any) => {
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    const today = format(new Date(), "dd_MM_yyyy_hh_mm");
    downloadLink.download = "expense-categories-" + today + ".xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="lg:hidden sm:flex sm:items-center">
        <div className="flex lg:hidden">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              {t("expense_categories")}
            </h1>
          </div>
          <div className="flex flex-row-reverse w-full">
            <div>
              <button
                type="button"
                onClick={() => setExpenseCategoryModalVisibility(true)}
                className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => downloadExpenseCategoriesList()}
                className="block px-3 py-2 mx-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
                  />
                </svg>
              </button>
            </div>
            <Filters
              clearFilterList={isClearFilterList}
              filters={filters}
              fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
            ></Filters>
          </div>
        </div>
      </div>
      <div className="hidden lg:block lg:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("expense_categories")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("expense_category_description")}
          </p>
        </div>
        <div className="mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <div className="mr-2">
            <Filters
              clearFilterList={isClearFilterList}
              filters={filters}
              fetchList={(appliedFilters: any) => handleFilters(appliedFilters)}
            ></Filters>
          </div>
          <button
            type="button"
            onClick={() => setExpenseCategoryModalVisibility(true)}
            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("add_expense_category")}
          </button>
          <button
            type="button"
            onClick={() => downloadExpenseCategoriesList()}
            className="block px-3 py-2 ml-2 text-sm font-semibold text-center text-white capitalize rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("export")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("name")}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize"
                    >
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {expenseCategories && expenseCategories.length > 0 ? (
                    expenseCategories.map((expenseCategory) => (
                      <tr key={expenseCategory.id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                          {expenseCategory.name}
                        </td>

                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                          <button
                            onClick={() =>
                              handleEditExpenseCategory(expenseCategory)
                            }
                            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {t("edit")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={2}
                        className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        {t("Record Not Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                total={meta?.total}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isExpenseCategoryModalOpen && (
        <Modal
          title={
            editableId ? "Update Expense Category" : "Add Expense Category"
          }
          onClose={() => handleCloseModal()}
          modalContent={
            <AddExpenseCategory
              editableId={editableId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default ExpenseCategoryList;
