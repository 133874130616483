import React, { useState } from "react";
import { Event } from "../../interfaces/Event";
import { format } from "date-fns";
import { ListVisitItem } from "../../interfaces/Visit";
import { changeVisitStatusById, getVisit } from "../../apis/visit";
import VisitContextMenu from "../visits/VisitContextMenu";

const DaysEvents: React.FC<{
  events: Array<Event> | null;
  date: any;
  closeModal: () => void;
  handleEventFormVisibility: () => void;
  handleReschedule?: (visit: ListVisitItem) => void;
  handleFollowUp?: (visit: ListVisitItem) => void;
}> = ({
  events,
  date,
  closeModal,
  handleEventFormVisibility,
  handleReschedule,
  handleFollowUp,
}) => {
  const [visibleContextMenu, setVisibleContextMenu] = useState<number | null>(
    null
  );
  const [currentVisit, setCurrentVisit] = useState<ListVisitItem | null>(null);

  const handleViewEvent = (event: Event, index: any) => {
    if (event.isAppointment && event.id) {
      getVisit(event.id).then((res: any) => {
        setCurrentVisit(res);
        setVisibleContextMenu(index);
      });
    }
  };

  return (
    <div className="">
      <div className="mt-4 border-t border-gray-100">
        {events && events.length > 0 ? (
          events
            ?.sort(
              (a, b) =>
                new Date(a.startDate).getTime() -
                new Date(b.startDate).getTime()
            )
            .map((item: any, index: number) => (
              <div
                key={index}
                className="py-2 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 even:bg-gray-50"
              >
                <div className="flex flex-col w-full">
                  <div className="flex items-center justify-between font-medium text-gray-900">
                    <div className="mt-1 ml-4 text-gray-500">
                      {!item.isAppointment && (
                        <span className="capitalize">
                          Title:{" "}
                          <span className="font-bold capitalize">
                            {item?.title}
                          </span>
                        </span>
                      )}
                      {item.isAppointment && (
                        <div className="flex">
                          <span className="pr-2 ">Patient: </span>
                          <span className="font-bold capitalize">
                            {item.patientName}
                          </span>
                        </div>
                      )}
                      <div className="flex">
                        <span className="pr-2">Assignee: </span>
                        <span className="font-bold capitalize">
                          {item.user}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="pr-2">
                          {item.isAppointment ? "Time:" : "Start Time:"}
                        </span>
                        <span className="font-bold capitalize">
                          {format(new Date(item?.startDate), "hh:mm a")}
                        </span>
                      </div>
                      {item.endDate && (
                        <div className="flex">
                          <span className="pr-2">End Time: </span>
                          <span className="font-bold capitalize">
                            {format(new Date(item?.endDate), "hh:mm a")}
                          </span>
                        </div>
                      )}
                      <div className="flex items-center gap-2 py-1.5">
                        <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-indigo-700 capitalize rounded-md bg-indigo-50 ring-1 ring-inset ring-indigo-500/10">
                          {item?.eventCategory?.name}
                        </span>

                        {item.isAppointment && (
                          <span>
                            {item?.status === "scheduled" && (
                              <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-pink-800 capitalize bg-pink-100 rounded-md ring-1 ring-inset ring-pink-500/10 dark:bg-pink-200 dark:text-pink-900">
                                {item.status}
                              </span>
                            )}
                            {item?.status === "checked in" && (
                              <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-800 capitalize bg-blue-100 rounded-md ring-1 ring-inset ring-blue-500/10 dark:bg-blue-200 dark:text-blue-900">
                                {item.status}
                              </span>
                            )}
                            {item?.status === "completed" && (
                              <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-green-800 capitalize bg-green-100 rounded-md ring-1 ring-inset ring-green-500/10 dark:bg-green-200 dark:text-green-900">
                                {item.status}
                              </span>
                            )}
                            {item?.status === "cancelled" && (
                              <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-800 capitalize bg-red-100 rounded-md ring-1 ring-inset ring-red-500/10 dark:bg-red-200 dark:text-red-900">
                                {item.status}
                              </span>
                            )}
                            {item?.status === "expired" && (
                              <span className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-800 capitalize bg-gray-100 rounded-md ring-1 ring-inset ring-gray-500/10 dark:bg-gray-200 dark:text-gray-900">
                                {item.status}
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="flex items-center">
                        {item.isAppointment && (
                          <span
                            className="ml-4 cursor-pointer "
                            onClick={() => {
                              handleViewEvent(item, index);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 0c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0 10c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0 10c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z" />
                            </svg>
                          </span>
                        )}
                        {visibleContextMenu === index && currentVisit && (
                          <div className="absolute right-0 z-10 bg-white border border-gray-200 rounded-md shadow-lg">
                            <VisitContextMenu
                              visit={currentVisit}
                              onReschedule={() =>
                                handleReschedule?.(currentVisit)
                              }
                              onFollowUp={() => handleFollowUp?.(currentVisit)}
                              onStatusChange={(visitId, status) => {
                                changeVisitStatusById({
                                  id: visitId,
                                  visitStatus: status,
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="flex p-4">
            <span className="w-full text-center">No Events Found!</span>
          </div>
        )}
      </div>

      <div className="flex items-center justify-end w-full pt-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => closeModal()}
          type="button"
          className="px-3 py-2 text-sm font-semibold text-gray-900 border rounded-md shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DaysEvents;
