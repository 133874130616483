import React, { useEffect, useState } from "react";
import { Transaction } from "../../interfaces/Visit";
import { getPaymentSummary, getVisitTransactions } from "../../apis/visit";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import AddVisitTransaction from "./AddVisitTransaction";
import { format } from "date-fns";
const VisitTransactionList = () => {
  const params = useParams();
  const [editableId, setEditableId] = useState(null);

  const [transactions, setTransactions] = useState<Array<Transaction>>([
    {
      amount: 0,
      date: "",
      paymentMode: "",
      note: "",
      visitId: null,
    },
  ]);
  const [isTransactionModalOpen, setTransactionModalVisibility] =
    useState<boolean>(false);
  const [total, setTotal] = useState<any>();
  const [amountPaid, setAmountPaid] = useState<any>();
  const [amountDue, setAmountDue] = useState<any>();
  const [discount, setDiscount] = useState<any>();

  useEffect(() => {
    if (params.visitId) {
      fetchTransactions();
      getVisitTotal();
    }
  }, [params]);

  const fetchTransactions = () => {
    getVisitTransactions(params.visitId).then((res: any) => {
      setTransactions(res.data.data);
    });
  };

  const handleCloseModal = () => {
    setEditableId(null);
    setTransactionModalVisibility(false);
    fetchTransactions();
  };

  const handleEditTransaction = (payload: any) => {
    setEditableId(payload.id);
    setTransactionModalVisibility(true);
  };

  const getVisitTotal = () => {
    getPaymentSummary(params.visitId).then((res: any) => {
      const visitTransactions = res.data.data;
      setTotal(visitTransactions.total);
      setAmountPaid(visitTransactions.amountPaid);
      setAmountDue(visitTransactions.amountDue);
      setDiscount(visitTransactions.discount);
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Transactions
          </h1>
          <div className="grid gap-2">
            <p className="mt-1 text-sm text-gray-500">
              Total Amount:{" "}
              <span className="font-semibold text-gray-800">
                ₹{(total || 0).toFixed(2)}
              </span>
              <span className="mt-1 ml-6 text-sm text-gray-500">
              Discount:{" "}
              <span className="font-semibold text-gray-800">₹{discount}</span>
              </span>
            </p>
            <p className="mt-1 text-sm text-gray-500">
             
              Amount Paid:{" "}
                <span className="font-semibold text-gray-800">
                  ₹{(amountPaid || 0).toFixed(2)}
                </span>
              <span className="mt-1 ml-6 text-sm text-gray-500">
                Amount Due:{" "}
                <span className="font-semibold text-gray-800">
                  ₹{(amountDue || 0).toFixed(2)}
                </span>
              </span>
            </p>
          </div>
        </div>
        <div className="mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <button
            type="button"
            onClick={() => setTransactionModalVisibility(true)}
            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create New
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Date
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Payment Mode
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Payment Note
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {transactions &&
                    transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                          {transaction.date &&
                            format(new Date(transaction.date), "dd MMM yyyy")}
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {transaction.amount}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap capitalize">
                          {transaction.paymentMode}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          {transaction.note ? transaction.note : "--"}
                        </td>

                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                          <button
                            onClick={() => handleEditTransaction(transaction)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isTransactionModalOpen && (
        <Modal
          title={editableId ? "Update Transaction" : "Add Transaction"}
          onClose={() => handleCloseModal()}
          modalContent={
            <AddVisitTransaction
              visitId={null}
              editableId={editableId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default VisitTransactionList;
