import React, { useContext, useEffect, useState } from 'react';
import {
  getExpenseCategory,
  saveExpenseCategory,
  updateExpenseCategory,
} from '../../apis/expenseCategory';
import { useNotification } from '../../NotificationContext';
import { LanguageContext } from '../../providers/LanguageProvider';
const AddExpenseCategory: React.FC<{
  closeModal: () => void;
  editableId: any;
}> = ({ closeModal, editableId }) => {
  const defaultExpenseCategories = {
    name: '',
  };
  const [expenseCategory, setExpenseCategory] = useState(
    defaultExpenseCategories
  );
  const [errors, setErrors] = useState<any>();
  const { showNotification } = useNotification();
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    if (editableId) {
      fetchExpenseCategory(editableId);
    }
  }, [editableId]);

  const fetchExpenseCategory = (id: number) => {
    getExpenseCategory(id).then((res: any) => {
      setExpenseCategory(res.data);
    });
  };
  const validateForm = () => {
    const validationErrors: any = {};
    if (expenseCategory?.name === '') {
      validationErrors.name = '* Name is required';
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const AddExpenseCategory = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveExpenseCategory({
      ...expenseCategory,
    }).then((res: any) => {
      showNotification(
        'success',
        'Expense Category Added successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };

  const updateExpenseCategoryData = () => {
    updateExpenseCategory({
      id: editableId,
      ...expenseCategory,
    }).then((res) => {
      showNotification(
        'success',
        'Eexpense category Updated successfully',
        '',
        'topRight'
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateExpenseCategoryData();
    } else {
      AddExpenseCategory();
    }
  };

  return (
    <div className=''>
      <div className='pt-2'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          {t('name')}
        </label>
        <div className='mt-1'>
          <input
            id='name'
            name='name'
            type='text'
            value={expenseCategory?.name}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[a-zA-Z]*$/.test(value)) {
                setExpenseCategory({ ...expenseCategory, name: e.target.value })
              }
            }}
            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
        </div>

        {errors && errors.name && (
          <span className='text-sm text-red-500 leading-0'>{errors.name}</span>
        )}
      </div>

      <div className='flex items-center justify-start py-4 gap-x-6 border-gray-900/10 '>
        <button
          onClick={() => onSave()}
          type='submit'
          className='px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark'
        >
          {editableId ? 'Update' : 'Save'}
        </button>
        <button
          onClick={() => closeModal()}
          type='button'
          className='text-sm font-semibold leading-6 text-gray-900'
        >
          {t('cancel')}
        </button>
      </div>
    </div>
  );
};

export default AddExpenseCategory;
