import React, { useContext, useEffect, useState } from "react";
import { useNotification } from "../../NotificationContext";
import { message } from "antd";
import { LanguageContext } from "../../providers/LanguageProvider";
import { getVisitType, saveVisitType, updateVisitType } from "../../apis/visit";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const AddVisitType: React.FC<{
  closeModal: () => void;
  replicatedVisitId: number | null;
  editableId: any;
}> = ({ closeModal, replicatedVisitId, editableId }) => {
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<any>();
  const { translate: t } = useContext(LanguageContext);
  const [, setIcon] = useState("");
  const defaultVisitType = {
    name: "",
    code: "",
    icon: "",
    isVisible: false,
    replicatedVisitId,
  };
  const [visitType, setVisitType] = useState(defaultVisitType);
  const [iconUrl, setIconUrl] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (replicatedVisitId) {
      setVisitType({ ...visitType, replicatedVisitId });
    }
  }, [replicatedVisitId]);
  useEffect(() => {
    if (editableId) {
      fetchVisitType(editableId);
    }
  }, [editableId]);

  const fetchVisitType = (id: number) => {
    getVisitType(id).then((res: any) => {
      setVisitType(res);
    });
  };

  const addVisitType = () => {
    const { hasErrors, validationErrors } = validateForm();
    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }
    setErrors(null);
    saveVisitType({ ...visitType }).then((res: any) => {
      showNotification(
        "success",
        "visit type added successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const updateVisitTypeData = () => {
    updateVisitType({
      id: editableId,
      ...visitType,
    }).then((res) => {
      showNotification(
        "success",
        "Visit Type Updated successfully",
        "",
        "topRight"
      );
      closeModal();
    });
  };

  const onSave = () => {
    if (editableId) {
      updateVisitTypeData();
    } else {
      addVisitType();
    }
  };
  const setVisitTypeName = (name: string) => {
    setVisitType({
      ...visitType,
      name: name,
    });
  };

  const cancel = () => {
    closeModal();
  };

  const validateForm = () => {
    const validationErrors: any = {};
    if (visitType?.name === "") {
      validationErrors.name = "* Name is required";
    }

    const hasErrors = Boolean(Object.keys(validationErrors).length);

    return { hasErrors, validationErrors };
  };

  const beforeUploadingImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  };

  const customImageSetter = (arg: any) => {
    setIcon(arg.file);
  };

  const handleIconChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      setVisitType((prev: any) => ({
        ...prev,
        icon: info.file.originFileObj as RcFile,
      }));
      setIconUrl(URL.createObjectURL(info.file.originFileObj as RcFile));
    }
  };

  const UploadButton = () => {
    return (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload Image</div>
      </div>
    );
  };

  return (
    <div className="">
      <span className="font-medium">
        {editableId ? t("update") : t("add")} {t("visit_type")}
      </span>
      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {t("name")}
        </label>
        <div className="mt-1">
          <input
            id="about"
            name="about"
            type="text"
            value={visitType?.name}
            onChange={(e) => setVisitTypeName(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        {errors && errors.name && (
          <span className="text-sm text-red-500 leading-0">{errors.name}</span>
        )}
      </div>

      <div className="pt-2">
        <label className="block text-sm font-medium leading-6 text-gray-900">
          Icon
        </label>
        <div className="mt-1">
          <Upload
            name="icon"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUploadingImage}
            customRequest={customImageSetter}
            onChange={handleIconChange}
          >
            {visitType.icon ? (
              <img src={iconUrl} alt="icon" style={{ width: "100%" }} />
            ) : (
              <UploadButton />
            )}
          </Upload>
        </div>
      </div>

      <div className="flex items-center justify-start py-4 gap-x-6 border-gray-900/10 ">
        <button
          onClick={() => onSave()}
          type="submit"
          className="px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dark"
        >
          {editableId ? t("update") : t("save")}
        </button>
        <button
          onClick={() => cancel()}
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          {t("cancel")}
        </button>
      </div>
    </div>
  );
};
export default AddVisitType;
