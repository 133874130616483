import React, { useContext, useEffect, useState } from "react";
import { Pagination, PaginationProps } from "antd";
import { RolesAndPermissions } from "../../interfaces/RolesAndPermissions";
import { getRolesAndPermissions } from "../../apis/rolesAndPermissions";
import Modal from "../../components/Modal";
import AddPermissions from "./AddPermissions";
import { LanguageContext } from "../../providers/LanguageProvider";
const RolesAndPermissionsList = () => {
  const [editableId, setEditableId] = useState(null);
  const [isRoleAndPermissionModalOpen, setRolesAndPermissionsModalVisibility] =
    useState<boolean>(false);

  const [rolesAndPermissions, setRolesAndPermissions] = useState<
    Array<RolesAndPermissions>
  >([]);
  const { translate: t } = useContext(LanguageContext);

  useEffect(() => {
    fetchRolesAndPermissions({ per_page: 10 });
  }, []);

  const handleCloseModal = () => {
    setEditableId(null);
    setRolesAndPermissionsModalVisibility(false);
    fetchRolesAndPermissions();
  };

  const fetchRolesAndPermissions = (params = {}) => {
    getRolesAndPermissions(params).then((res: any) => {
      setRolesAndPermissions(res);
    });
  };

  const hendleEditRolesAndPermissions = (payload: any) => {
    setEditableId(payload.id);
    setRolesAndPermissionsModalVisibility(true);
  };

  //use per_page
  const onPaginationChange: PaginationProps["onChange"] = (page, pageSize) => {
    fetchRolesAndPermissions({ page, per_page: pageSize });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="lg:hidden sm:flex sm:items-center">
        <div className="flex lg:hidden">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              {t("roles_and_permissions")}
            </h1>
          </div>
          <div className="flex flex-row-reverse w-full">
            <div>
              <button
                type="button"
                onClick={() => setRolesAndPermissionsModalVisibility(true)}
                className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hover"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("roles_and_permissions")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{t("roles_description")}</p>
        </div>
        <div className="mx-2 mt-4 sm:mt-0 sm:ml-16 sm:flex">
          <button
            type="button"
            onClick={() => setRolesAndPermissionsModalVisibility(true)}
            className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("add_permissions")}
          </button>
        </div>
      </div>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t("permissions")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("role")}
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 capitalize"
                    >
                      {t("actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rolesAndPermissions.map((item) => (
                    <tr key={item.id}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
                        {item.permissions}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {item.roles.join(", ")}
                      </td>

                      <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                        <button
                          onClick={() => hendleEditRolesAndPermissions(item)}
                          className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          {t("edit")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end pt-5">
              <Pagination
                defaultCurrent={1}
                defaultPageSize={10}
                onChange={onPaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isRoleAndPermissionModalOpen && (
        <Modal
        title= {editableId ? "Update Permissions" : "Add Permissions"}
        onClose={() => handleCloseModal()}
          modalContent={
            <AddPermissions
              editableId={editableId}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default RolesAndPermissionsList;
