import { Button, Dropdown } from "antd";
import { ListVisitItem } from "../../interfaces/Visit";
import { useContext, useState } from "react";
import { LanguageContext } from "../../providers/LanguageProvider";
import { useAppDispatch } from "../../hooks";
import { changeStep, changeVisit } from "../../features/visit/visitSlice";
import { useNavigate } from "react-router-dom";
import Prescription from "./Prescription";
import Modal from "../../components/Modal";

type VisitContextMenuProps = {
  visit: ListVisitItem;
  onFollowUp: (visit: ListVisitItem) => void;
  onStatusChange: (visit: ListVisitItem, status: string) => void;
  onReschedule: (visit: ListVisitItem) => void;
};

const VisitContextMenu: React.FC<VisitContextMenuProps> = ({
  visit,
  onFollowUp,
  onReschedule,
  onStatusChange,
}) => {
  const { translate: t } = useContext(LanguageContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isPrescriptionVisible, setIsPrescriptionVisible] = useState(false);

  const handleEdit = (visit: ListVisitItem) => {
    const editableVisit = { ...visit, symptoms: [], visitFiles: [] };
    dispatch(changeStep(1));
    dispatch(changeVisit(editableVisit));
    navigate(`/visits/${visit.id}`);
  };
  return (
    <div>
      <Dropdown
        menu={{
          items: [
            {
              key: "edit",
              label: (
                <span>
                  <button onClick={() => handleEdit(visit)}>
                    {t("visit_default_action")}
                  </button>
                </span>
              ),
              disabled:
                visit.visitStatus !== "completed" &&
                visit.visitStatus !== "expired" &&
                visit.visitStatus !== "cancelled"
                  ? false
                  : true,
            },

            {
              key: "view",
              label: (
                <span>
                  <a
                    href={`/view/visits/${visit.id}`}
                    rel="noopener noreferrer"
                  >
                    {t("view")}
                  </a>
                </span>
              ),
              disabled: visit.visitStatus === "completed" ? false : true,
            },
            {
              key: "follow_up",
              label: (
                <span>
                  <button
                    onClick={() => onFollowUp(visit)}
                    disabled={visit.visitStatus !== "completed"}
                  >
                    {t("create_follow_up_visit")}
                  </button>
                </span>
              ),
              disabled: visit.visitStatus === "completed" ? false : true,
            },

            {
              key: "reschedule",
              label: (
                <span>
                  <button
                    className="capitalize"
                    onClick={() => onReschedule(visit)}
                  >
                    {t("reschedule")}
                  </button>
                </span>
              ),
              disabled: visit.visitStatus !== "scheduled",
            },

            {
              key: "prescription",
              label: (
                <span onClick={() => setIsPrescriptionVisible(true)}>
                  {t("view_prescription")}
                </span>
              ),
              disabled: visit.visitStatus === "completed" ? false : true,
            },
            {
              key: "transactions",
              label: (
                <span>
                  <button
                    onClick={() => navigate(`/transactions/visits/${visit.id}`)}
                  >
                    {t("transactions")}
                  </button>
                </span>
              ),
            },

            {
              key: "status",
              label: "Change Status",
              children: [
                {
                  key: "checked_in",
                  label: (
                    <span>
                      <button
                        onClick={() => onStatusChange(visit.id, "checked_in")}
                        disabled={visit.visitStatus !== "scheduled"}
                      >
                        {t("checked_in")}
                      </button>
                    </span>
                  ),
                  disabled: visit.visitStatus === "scheduled" ? false : true,
                },

                {
                  key: "completed",
                  label: (
                    <span>
                      <button
                        onClick={() => onStatusChange(visit.id, "completed")}
                        disabled={
                          visit.visitStatus === "completed" ||
                          visit.visitStatus === "expired" ||
                          visit.visitStatus === "cancelled"
                        }
                      >
                        {t("completed")}
                      </button>
                    </span>
                  ),
                  disabled:
                    visit.visitStatus === "completed" ||
                    visit.visitStatus === "expired" ||
                    visit.visitStatus === "cancelled"
                      ? true
                      : false,
                },
                {
                  key: "cancelled",
                  label: (
                    <span>
                      <button
                        onClick={() => onStatusChange(visit.id, "cancelled")}
                        disabled={visit.visitStatus !== "scheduled"}
                      >
                        {t("cancelled")}
                      </button>
                    </span>
                  ),
                  disabled: visit.visitStatus === "scheduled" ? false : true,
                },
              ],
            },
          ],
        }}
        placement="bottomLeft"
      >
        <Button className="capitalize">{t("actions")}</Button>    
      </Dropdown>
      {isPrescriptionVisible && visit && (
        <Modal
          width="max-w-2xl"
          title="Prescription Preview"
          onClose={() => setIsPrescriptionVisible(false)}
          modalContent={
            <Prescription
              preview
              prescriptionDetails={{
                symptoms: visit.symptoms,
                medicines: visit.medicines,
                finalDiagnosis: visit.finalDiagnosis,
                provisionalDiagnosis: null,
                advice: visit.advice,
                patient: visit.patient,
                visitId: visit.id,
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default VisitContextMenu;
