import { useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AddTranslation from "./AddTranslation";
import { Select } from "antd";
import { getTranslations } from "../../apis/auth";
import { LanguageContext } from "../../providers/LanguageProvider";

const TranslationList = () => {
  const [translations, setTranslations] = useState<any>([]);
  const [isTranslationModalOpen, setTranslationModalVisibility] =
    useState<boolean>(false);
  const [editableId, setEditableId] = useState(null);
  const [language, setLanguage] = useState<string>("en");
  const Option = Select;

  const token = window.localStorage.getItem("token");
  const { translate: t } = useContext(LanguageContext);

  const languageOptions = [
    { name: "English", value: "en" },
    { name: "Hindi", value: "hn" },
    { name: "Marathi", value: "mr" },
  ];

  useEffect(() => {
    fetchTranslations(language);
  }, [language]);

  const fetchTranslations = async (locale: any) => {
    try {
      if (token) {
        const res: any = await getTranslations(locale);
        setTranslations(res.data);
      }
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
  };

  const handleCloseModal = () => {
    setTranslationModalVisibility(false);
    fetchTranslations(language);
    setEditableId(null);
  };

  const handleEditTranslation = (payload: any) => {
    setEditableId(payload.id);
    setTranslationModalVisibility(true);
  };

  return (
    <div className="block">
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            <b>Select Language</b>
          </h3>

          <div className="mt-1">
            <Select
              style={{ width: "20%" }}
              onChange={handleLanguageChange}
              value={language}
            >
              {languageOptions.map((item: any) => (
                <Option key={item.name} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="">
            <button
              type="button"
              onClick={() => setTranslationModalVisibility(true)}
              className="block float-right px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("add_new_translations")}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-6 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-sm font-medium leading-6 text-gray-900">
            <b> Translation Details</b>
          </h3>
        </div>

        <div className="mt-2 bg-white divide-y divide-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="flex items-center justify-between">
              <dt className="ml-6 text-sm font-bold text-gray-500">Key</dt>
              <dt className="ml-6 text-sm font-bold text-gray-500">Value</dt>

              <div className="relative py-2 pl-3 pr-4 text-sm font-bold text-gray-500 whitespace-nowrap sm:pr-6">
                Action
              </div>
            </div>
          </dl>
          {translations.map((item: any) => (
            <div key={item.id} className="group">
              <dl className="divide-y divide-gray-200">
                <div className="flex items-center justify-between">
                  <dt className="ml-6 text-sm font-medium text-gray-500">
                    {item.key}
                  </dt>
                  <dt className="ml-6 text-sm font-medium text-gray-500">
                    {item.value}
                  </dt>

                  <div className="relative py-2 pl-3 pr-4 text-sm font-medium text-left whitespace-nowrap sm:pr-6">
                    <button
                      onClick={() => handleEditTranslation(item)}
                      className="block px-3 py-2 text-sm font-semibold text-center text-white rounded-md shadow-sm bg-primary hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {t("edit")}
                    </button>
                  </div>
                </div>
              </dl>
            </div>
          ))}
        </div>
      </div>
      {isTranslationModalOpen && (
        <Modal
        title= {editableId ? "Update Translation" : "Add Translation"}
        onClose={() => handleCloseModal()}
          modalContent={
            <AddTranslation
              editableId={editableId}
              language={language}
              closeModal={() => handleCloseModal()}
            />
          }
        />
      )}
    </div>
  );
};

export default TranslationList;
