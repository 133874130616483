import React from "react";
import { Event } from "../../interfaces/Event";
import { format, getMonth } from "date-fns";
import { getVisit } from "../../apis/visit";
import { ListVisitItem } from "../../interfaces/Visit";

type CalendarDay = {
  date: Date;
  events: Array<Event>;
};

type MonthViewProps = {
  calendarDays: Array<CalendarDay>;
  onDayClick: (day: CalendarDay) => void;
  selectedDay: Date | null;
  firstDayCurrentMonth: Date;
  onViewDetails: (event: Event) => void;
  onViewVisit: (visit: ListVisitItem, event: Event) => void;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const MonthView: React.FC<MonthViewProps> = ({
  calendarDays,
  onDayClick,
  onViewDetails,
  selectedDay,
  firstDayCurrentMonth,
  onViewVisit,
}) => {
  const handleViewEvent = (event: Event) => {
    if (event.isAppointment && event.id) {
      getVisit(event.id).then((res: any) => {
        onViewVisit(res, event);
      });
    } else {
      onViewDetails(event);
    }
  };
  return (
    <div className="shadow ring-1 ring-black/5 lg:flex lg:flex-auto lg:flex-col">
      <div className="grid grid-cols-7 gap-px font-semibold text-center text-gray-700 bg-gray-200 border-b border-gray-300 text-xs/6 lg:flex-none">
        <div className="flex justify-center py-2 bg-white">
          <span>S</span>
          <span className="sr-only sm:not-sr-only">un</span>
        </div>
        <div className="flex justify-center py-2 bg-white">
          <span>M</span>
          <span className="sr-only sm:not-sr-only">on</span>
        </div>
        <div className="flex justify-center py-2 bg-white">
          <span>T</span>
          <span className="sr-only sm:not-sr-only">ue</span>
        </div>
        <div className="flex justify-center py-2 bg-white">
          <span>W</span>
          <span className="sr-only sm:not-sr-only">ed</span>
        </div>
        <div className="flex justify-center py-2 bg-white">
          <span>T</span>
          <span className="sr-only sm:not-sr-only">hu</span>
        </div>
        <div className="flex justify-center py-2 bg-white">
          <span>F</span>
          <span className="sr-only sm:not-sr-only">ri</span>
        </div>
        <div className="flex justify-center py-2 bg-white">
          <span>S</span>
          <span className="sr-only sm:not-sr-only">at</span>
        </div>
      </div>
      <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
        <>
          {calendarDays &&
            calendarDays.map((day: any, i: number) => (
              <div
                key={i}
                data-index={i}
                onClick={() => onDayClick(day)}
                className={classNames(
                  day.date === selectedDay
                    ? "bg-indigo-100"
                    : day.date !== selectedDay &&
                      getMonth(new Date(day.date)) ===
                        getMonth(new Date(firstDayCurrentMonth))
                    ? "bg-white"
                    : "bg-gray-100 text-gray-500",
                  " relative px-1 py-1 text-sm hover:bg-indigo-50 text-gray-900 border min-h-24 border-gray-200"
                )}
              >
                <span>
                  <span
                    className={classNames(
                      format(day.date, "yyyy-MM-dd") ===
                        format(new Date(), "yyyy-MM-dd")
                        ? "flex mb-1  w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white hover:cursor-pointer"
                        : ""
                    )}
                  >
                    {format(new Date(day.date), "d")}
                  </span>

                  <div className="hidden px-1 lg:block text-primary">
                    {day?.events &&
                      day?.events.map((event: Event, index: number) => (
                        <div key={index}>
                          {index < 2 && (
                            <div className="">
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleViewEvent(event);
                                }}
                                className={classNames(
                                  event?.status && event.status === "completed"
                                    ? "bg-green-400"
                                    : "bg-indigo-400",
                                  "inline-block w-full px-2 py-0.5 text-xs text-white text-ellipsis capitalize truncate  rounded-md cursor-pointer group-hover:text-indigo-600"
                                )}
                              >
                                {event.eventCategory?.name === "appointment" ? (
                                  <span>{`${event.patientName} (${format(
                                    new Date(event.startDate),
                                    "hh:mm a"
                                  )})`}</span>
                                ) : (
                                  <span>{event.title}</span>
                                )}
                                <span></span>
                              </span>
                            </div>
                          )}
                        </div>
                      ))}

                    {day?.events.length > 2 && (
                      <span className="list-disc ">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            onDayClick(day);
                          }}
                          className="px-2 py-0.5 text-xs text-white capitalize truncate bg-indigo-400 rounded-md cursor-pointer group-hover:text-indigo-600"
                        >
                          More Events..
                        </span>
                      </span>
                    )}
                  </div>
                </span>
              </div>
            ))}
        </>
      </div>
    </div>
  );
};

export default MonthView;
